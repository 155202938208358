import { fetchData } from "src/hooks/customHooks";
import apiClient from "src/middlewares/api-client";
import { WithdrawConfirm, WithdrawRequest } from "src/types/wallet";

import { WALLET_PATH } from "./path";

type Transaction = {
  id: string;
  user_id: string;
  address: string;
  create_date: string;
  status: string;
  asset_id: string;
  asset_code: string;
  tx_id: string;
  amount: number;
  commission: any;
  transaction_type: string;
  direction: string;
  address_from: any;
  address_to: any;
  network: any;
  transaction_time: string;
};

type Pagination = {
  items: number;
  pages: number;
  page: number;
  perpage: number;
};

type Response<T = any> = {
  items: T[];
  pagination: Pagination;
};

export const getTransactions = (
  limit: number,
  page: number,
  type: string
): Promise<Response<Transaction>> => {
  return fetchData(
    `${WALLET_PATH.TRANSACTIONS}?limit=${limit}&page=${page}&type=${type}`,
    {}
  );
};

export const getDepositAddress = (asset_code: string) => {
  return apiClient.post(WALLET_PATH.GET_DEPOSIT_ADDRESS, { asset_code });
};

export const withdrawRequest = (data: WithdrawRequest) => {
  return apiClient.post(
    WALLET_PATH.WITHDRAW_REQUEST,
    sanitizeWithdrawRequest(data)
  );
};

export const withdrawConfirm = (data: WithdrawConfirm) => {
  return apiClient.post(WALLET_PATH.WITHDRAW_CONFIRM, data);
};

const sanitizeWithdrawRequest = ({
  address,
  asset_code,
  network,
  amount,
}: WithdrawRequest) => ({
  address,
  asset_code,
  network,
  amount: Number(amount),
});
