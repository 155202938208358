import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { formatNumber } from "src/utils";
import { useGetMarkets, cal24hRate } from "src/hooks/useMarket";

import styles from "./Cards.module.sass";

const Cards = ({ className }) => {
  const { hotMarkets } = useGetMarkets();
  const [markets, setMarkets] = useState([]);
  const imgBasePath = "/images/content/currency/";
  useEffect(() => {
    if (hotMarkets) {
      setMarkets(hotMarkets.slice(0, 4));
    }
  }, [hotMarkets]);

  return (
    <div className={cn(className, styles.cards)}>
      {markets?.map((x, index) => (
        <Link className={styles.card} key={index} to={"/market"}>
          <div className={styles.icon}>
            <img
              src={imgBasePath + x.base_currency_code + ".svg"}
              alt="Currency"
            />
          </div>
          <div className={styles.details}>
            <div className={styles.line}>
              <div className={styles.title}>{x.base_currency_code}</div>
              {x.change_24 >= 0 && (
                <div className={styles.positive}>
                  {cal24hRate(x.last_price, x.change_24)}%
                </div>
              )}
              {x.change_24 < 0 && (
                <div className={styles.negative}>
                  {cal24hRate(x.last_price, x.change_24)}%
                </div>
              )}
            </div>
            <div className={styles.price}>{formatNumber(x.last_price)}</div>
            <div className={styles.money}>{formatNumber(x.last_price)}</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Cards;
