import React from "react";
import { useGetUserInfo } from "src/hooks/useUser";

type Props = {
  children: React.ReactElement;
  isCheckAuth?: boolean;
};

export const AuthProvider = ({ children, isCheckAuth = true }: Props) => {
  const { isAuth } = useGetUserInfo();

  if (!isCheckAuth) {
    return !isAuth ? children : null;
  }

  return isAuth ? children : null;
};
