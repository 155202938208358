import { createModel } from "@rematch/core";

import apiClient from "../../middlewares/api-client";

import { RootModel } from ".";

export interface WalletState {
  permanentInfo: any[];
  balance: any;
}

const initState = {
  permanentInfo: [],
  balance: undefined,
} as WalletState;

export const wallet = createModel<RootModel>()({
  state: initState,
  reducers: {
    updatePermanentInfo(state, permanentInfo) {
      state.permanentInfo = permanentInfo;
    },
    setBanlance(state, balance) {
      state.balance = balance;
    },

    reset() {
      return initState;
    },
  },
  effects: () => ({
    async fetchPermanentInfo(forceUpdate = false, rootState) {
      if (!forceUpdate && rootState.wallet.permanentInfo?.length > 0) {
        return;
      }

      const response = await apiClient.post(
        "currency/merchant/address/permanent/all/"
      );
      const { message: permanentInfo } = response.data || [];
      this.updatePermanentInfo(permanentInfo);

      return true;
    },
    async fetchBalance() {
      const response = await apiClient.post("balance/merchant/all/");
      const { message: balance } = response.data || [];
      this.setBanlance(balance);

      return true;
    },
    async withdraw(data: any) {
      await apiClient.post("currency/merchant/withdraw/", data);
      await this.fetchBalance();

      return true;
    },
    async generateAddress(data: any) {
      try {
        await apiClient.post(
          "currency/merchant/address/permanent/create/",
          data
        );
        await this.fetchPermanentInfo(true);

        return true;
      } catch (error) {
        console.log(error);

        return false;
      }
    },
  }),
});
