import cn from "classnames";
import { useAboutRequest } from "src/hooks/useCmsHook";

import styles from "./Content.module.sass";

const Content = () => {
  const { data: response } = useAboutRequest() || {};

  return (
    <div className={styles.section}>
      <div className={styles.title}>{response?.data?.Title}</div>
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: `${response?.data?.Content}` }}
      ></div>
    </div>
  );
};

export default Content;
