import React, { useState, useEffect } from "react";
import cn from "classnames";

import { useGetHistories } from "src/hooks/useWallet";
import Actions from "src/components/Actions";
import Dropdown from "src/components/Dropdown";

import styles from "./Activity.module.sass";
import Table from "./Table";

const navigation = ["All type", "Withdrawals", "Deposit"];

const Activity = () => {
  // const [email, setEmail] = useState("");
  const [type, setType] = useState("all");
  const [activeIndex, setActiveIndex] = useState(0);
  const limit = 10;
  // const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState(navigation[0]);

  const { data } = useGetHistories({ page: 1, limit, type });

  useEffect(() => {
    switch (activeIndex) {
      case 0:
        setType("all");
        break;
      case 1:
        setType("withdrawal");
        break;
      case 2:
        setType("deposit");
        break;
      default:
        setType("all");
    }
  }, [activeIndex]);

  useEffect(() => {
    switch (sorting) {
      case "All type":
        setType("all");
        break;
      case "Withdrawals":
        setType("withdrawal");
        break;
      case "Deposit":
        setType("deposit");
        break;
      default:
        setType("all");
    }
  }, [sorting]);

  // const handleSubmit = (e) => {
  //   alert();
  // };

  return (
    <div className={styles.activity}>
      <div className={cn("container", styles.container)}>
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={styles.nav}>
              {navigation.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={navigation}
              />
            </div>
            {/* <form
              className={styles.form}
              action=""
              onSubmit={() => handleSubmit()}
            >
              <input
                className={styles.input}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="search"
                placeholder="Search"
                required
              />
              <button className={styles.result}>
                <Icon name="search" size="20" />
              </button>
            </form> */}
            {/* <Calendar className={styles.calendar} /> */}
          </div>
          {/* <div className={styles.line}>
            <h4 className={cn("h4", styles.title)}>Activity</h4>
            <Export className={styles.export} />
          </div> */}
          <Table className={styles.table} items={data} />
        </div>
        {/* <Actions className={styles.actions} /> */}
      </div>
    </div>
  );
};

export default Activity;
