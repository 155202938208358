import React, { useState } from "react";

import Bidding from "../../components/Bidding";

import Popular from "../../components/Popular";

import { currencySymbol } from "../../utils";

import SelectCurrency from "./SelectCurrency";
import BankDetail from "./ImportantNotes";
import PaymentDetails from "./PaymentDetails";

const steps = ["Select currency", "Bank details", "Payment details"];

const DepositFiat = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [depositDetail, setDepositDetail] = useState({});

  const handleGoNote = (amount, currency) => {
    setDepositDetail({
      amount: amount,
      currency: currency,
      symbol: currencySymbol(currency),
    });
    setActiveIndex(1);
  };
  const handleGoDetail = (usd_amount) => {
    let tmp_detail = depositDetail;
    tmp_detail["usd_amount"] = usd_amount;
    setDepositDetail(tmp_detail);
    setActiveIndex(2);
  };
  return (
    <>
      <Bidding title="Deposit fiat" items={steps} activeIndex={activeIndex}>
        {activeIndex === 0 && <SelectCurrency goNext={handleGoNote} />}
        {activeIndex === 1 && (
          <BankDetail depositDetail={depositDetail} goNext={handleGoDetail} />
        )}
        {activeIndex === 2 && <PaymentDetails paymentAmount={depositDetail} />}
      </Bidding>
      {/* <Popular classSection="section-bg section-mb0" /> */}
    </>
  );
};

export default DepositFiat;
