import React from "react";
import cn from "classnames";

import { RoutePath } from "src/routes/path";
import Login from "src/components/Login";
// import Icon from "src/components/Icon";

import styles from "./SignIn.module.sass";
import Form from "./Form";
// import Scan from "./Scan";

const SignIn = () => {
  // const [scan, setScan] = useState(false);

  return (
    <Login
      content="Don’t have an account?"
      linkText="Sign up for free"
      linkUrl={RoutePath.SIGN_UP}
    >
      <div className={styles.login}>
        <div className={styles.top}>
          <h3 className={cn("h3", styles.title)}>Sign in to Byta</h3>
          {/* <div className={styles.info}>
            Please ensure you are visiting the correct url.
          </div> */}
          {/* <div className={styles.correct}>
            <Icon name="lock" size="24" />
            <div className={styles.url}>
              https://<span>accounts.Byta.com/login</span>
            </div>
          </div> */}
        </div>
        <Form />
        {/* {scan ? <Scan /> : <Form onScan={() => setScan(true)} />} */}
      </div>
    </Login>
  );
};

export default SignIn;
