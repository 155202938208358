import Company from "../../components/Company";

import Content from "./Content";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Developer Tools",
  },
];

const DeveloperTools = () => {
  return (
    <Company title="Developer Tools" breadcrumbs={breadcrumbs}>
      <Content />
    </Company>
  );
};

export default DeveloperTools;
