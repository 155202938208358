/* eslint-disable react/no-unescaped-entities */
import cn from "classnames";
import { toast } from "react-toastify";

import TextField from "../../../components/TextInput";

import styles from "./TwoFaDisabled.module.sass";

const TwoFaDisabled = ({ enableMFA, userInfo }) => {
  const onSubmit = () => {
    if (!userInfo?.phone) {
      toast.warn("Please update your phone");
      return;
    }
    enableMFA();
  };

  return (
    <div>
      <div className={cn("h3", styles.title)}>Set up 2-step verification</div>
      <div className={styles.subtitle}>Enable 2FA</div>
      <div className={styles.info}>
        Make sure your phone number is correct so we can send you the
        verification code if necessary
      </div>
      <TextField
        className={styles.field}
        label="phone"
        name="phone"
        type="tel"
        placeholder="Phone Number"
        value={userInfo?.phone ?? ""}
        required
        disabled={true}
      />
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={onSubmit}>
          Enable
        </button>
      </div>
    </div>
  );
};

export default TwoFaDisabled;
