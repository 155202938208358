import Legal from "../../components/Legal";

import Content from "./Content";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "AML/CFT",
  },
];

const AmlCft = () => {
  return (
    <Legal title="AML/CFT" breadcrumbs={breadcrumbs}>
      <Content />
    </Legal>
  );
};

export default AmlCft;
