import React from "react";
// import { Box } from '@mui/material'
import { useGetDataExchange, useGetHistories } from "src/hooks/useExchange";
import { formatNumber } from "src/utils";

import styles from "../Table.module.sass";
// import Loader from 'components/Loader'

const MyTrades = () => {
  const { baseCurrencyCode, quoteCurrencyCode, symbol, marketId } =
    useGetDataExchange();
  const { data } = useGetHistories({ symbol, marketid: marketId });

  return (
    <React.Fragment>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">Time</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Action</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Amount ({baseCurrencyCode})</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Price ({quoteCurrencyCode})</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Total ({quoteCurrencyCode})</div>
          </div>
        </div>
        {data?.items?.map((x: any, index: number) => (
          <div className={styles.row} key={index}>
            <div className={styles.col}>{x.create_date}</div>
            <div className={styles.col}>
              {x.side === "BUY" && <div className={styles.positive}>BUY</div>}
              {x.side === "SELL" && <div className={styles.negative}>SELL</div>}
            </div>
            <div className={styles.col}>{formatNumber(x.quantity)}</div>
            <div className={styles.col}>{formatNumber(x.price)}</div>
            <div className={styles.col}>
              {formatNumber(x.price * x.quantity)}
            </div>
          </div>
        ))}
      </div>
      {/* {isLoading && (
        <Box display="flex" justifyContent="center" width="100%" py={5}>
          <Loader className={undefined} color={undefined} />
        </Box>
      )} */}
    </React.Fragment>
  );
};

export default MyTrades;
