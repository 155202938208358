const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <h2>Oops, there is an error!</h2>
      <h4>{error.message}</h4>
      <button
        type="button"
        onClick={
          () => resetErrorBoundary
          // TODO reset errors
          //this?.setState({ hasError: false })
        }
      >
        Try again?
      </button>
    </div>
  );
};
export default ErrorFallback;
