import React, { useState, useEffect } from "react";
import cn from "classnames";

import { useMediaQuery } from "react-responsive";

import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";

import styles from "./Exchange.module.sass";
import Main from "./Main";
import OrderBook from "./OrderBook";
import Currency from "./Currency";
import HistoryTrades from "./HistoryTrades";
import Table from "./Table";
import Actions from "./Actions";
import Charts from "./Charts";

const navigation = ["Chart", "Order books", "Trades"];

const Exchange = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const { symbol } = useParams();
  const markets = useSelector((rootState) => rootState.market?.markets);
  const [market, setMarket] = useState("");

  const handleChangeCurrency = (newMarket) => {
    setMarket(newMarket);
  };

  useEffect(() => {
    if (markets) {
      const _marketId = Object.keys(markets).filter(
        (m) => markets[m].symbol === symbol
      )[0];

      setMarket(markets[_marketId]);
    }
  }, [markets]);

  return (
    <div className={styles.exchange}>
      <Main market={symbol} />
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      {isTablet ? (
        <>
          <Actions
            baseCurrency={market.base_currency_code}
            quoteCurrency={market.quote_currency_code}
          />
          {activeIndex === 0 && (
            <div className={styles.box}>
              <Charts market={market} />
              <Table market={symbol} />
            </div>
          )}
          {activeIndex === 1 && (
            <div className={styles.box}>
              <OrderBook marketId={market.id} />
            </div>
          )}
          {activeIndex === 2 && (
            <div className={styles.box}>
              <Currency onChange={handleChangeCurrency} />
              <HistoryTrades market={symbol} />
            </div>
          )}
        </>
      ) : (
        <div className={styles.row}>
          <div className={styles.col}>
            <OrderBook marketId={market.id} />
          </div>
          <div className={styles.col}>
            <Charts market={market} />
            <Actions
              baseCurrency={market.base_currency_code}
              quoteCurrency={market.quote_currency_code}
            />
            <Table market={symbol} />
          </div>
          <div className={styles.col}>
            <Currency onChange={handleChangeCurrency} />
            <HistoryTrades market={symbol} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Exchange;
