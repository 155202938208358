import React, { useRef } from "react";

import { useEffect } from "react";

import { useSelector } from "react-redux";

import Popular from "../../components/Popular";

import News from "../../components/News";

import { useRematchDispatch } from "../../hooks";

import Main from "./Main";
import Learn from "./Learn";
import Trend from "./Trend";
import Download from "./Download";

import Steps from "./Steps";

const Home = () => {
  const scrollToRef = useRef(null);
  const { fetchAllMarkets } = useRematchDispatch((dispatch) => ({
    fetchAllMarkets: dispatch.market.fetchAllMarkets,
  }));

  const initMarkets = async () => {
    fetchAllMarkets();
  };
  useEffect(() => {
    initMarkets();
  }, []);
  return (
    <>
      <Main scrollToRef={scrollToRef} />
      {/* <Learn scrollToRef={scrollToRef} /> */}
      <Trend />
      {/* <Popular classSection="section-bg section-mb0" /> */}
      {/* <Download /> */}
    </>
  );
};

export default Home;
