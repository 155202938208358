import React from "react";
import cn from "classnames";

import { Link } from "react-router-dom";

import { AreaChart, Area, ResponsiveContainer } from "recharts";
import _ from "lodash";

import { transformQuotes } from "src/utils";
import Icon from "src/components/Icon";
import {
  useGetMarkets,
  useGetSimpleQuotes,
  cal24hRate,
} from "src/hooks/useMarket";
import { formatNumber } from "src/utils";

import styles from "./Trade.module.sass";

const Trade = () => {
  // TODO: will be replaced
  const imgBasePath = "/images/content/currency/";
  const { markets } = useGetMarkets();
  const marketIds = Object.keys(markets);
  const quotesQueries = useGetSimpleQuotes({ marketIds });

  return (
    <div className={styles.trade}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">#</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Name</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Price</div>
          </div>
          <div className={styles.col}>24h %</div>

          <div className={styles.col}>
            Volume (24h) <Icon name="chart" size="20" />
          </div>
          <div className={styles.col}>Chart (24h)</div>
        </div>
        {markets &&
          Object.keys(markets).map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>
                <div className={styles.line}>
                  <button className={cn("favorite", styles.favorite)}>
                    <Icon name="star-outline" size="16" />
                  </button>
                  {index + 1}
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.item}>
                  <div className={styles.icon}>
                    <img
                      src={imgBasePath + markets[x].base_currency_code + ".svg"}
                      alt="Currency"
                    />
                  </div>
                  <div className={styles.details}>
                    <span className={styles.subtitle}>
                      {markets[x].base_currency_code}
                    </span>
                    /
                    <span className={styles.currency}>
                      {markets[x].quote_currency_code}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Price</div>
                {formatNumber(markets[x].last_price, 8)}
              </div>
              <div className={styles.col}>
                <div className={styles.label}>24h</div>
                {markets[x].change_24 >= 0 && (
                  <div className={styles.positive}>
                    +{cal24hRate(markets[x].last_price, markets[x].change_24)}%
                  </div>
                )}
                {markets[x].change_24 < 0 && (
                  <div className={styles.negative}>
                    {cal24hRate(markets[x].last_price, markets[x].change_24)}%
                  </div>
                )}
              </div>

              {/* <div className={styles.col}>
              <div className={styles.label}>Marketcap</div>
              {markets[x].volume_24}
            </div> */}
              <div className={styles.col}>
                <div className={styles.label}>Volume (24h)</div>
                {markets[x].volume_24}
              </div>
              <div className={styles.col}>
                <div className={styles.chart}>
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      width={500}
                      height={400}
                      data={transformQuotes(
                        _.get(quotesQueries, `[${index}].data`, [])
                      )}
                      margin={{
                        top: 3,
                        right: 0,
                        left: 0,
                        bottom: 3,
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="colorPrice"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#45B36B"
                            stopOpacity={0.6}
                          />
                          <stop
                            offset="95%"
                            stopColor="#45B36B"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <Area
                        type="monotone"
                        dataKey="price"
                        stroke="#58BD7D"
                        fillOpacity={1}
                        fill="url(#colorPrice)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <Link
                  className={cn("button-small", styles.button)}
                  to={`/exchange/${markets[x].symbol}`}
                >
                  Buy
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Trade;
