import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";

import Icon from "../../../components/Icon";
import { useRematchDispatch } from "../../../hooks";

import styles from "./Currency.module.sass";
const navigation = ["All"]; //, "Cross", "Isolated"];

const Currency = ({ onChange }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  // const [search, setSearch] = useState("");
  const history = useHistory();

  // const handleSubmit = (e) => {
  //   alert();
  // };

  const handleChangeCurency = (currency) => {
    onChange && onChange(currency);
    history.push(`/exchange/${currency.symbol}`);
  };

  const { hotMarkets } = useSelector((rootState) => rootState.market);
  const { fetchAllMarkets } = useRematchDispatch((dispatch) => ({
    fetchAllMarkets: dispatch.market.fetchAllMarkets,
  }));

  useEffect(() => {
    fetchAllMarkets();
  }, []);

  return (
    <div className={styles.currency}>
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      {/* <form className={styles.form} action="" onSubmit={() => handleSubmit()}>
        <input
          className={styles.input}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder="Search"
          required
        />
        <button className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form> */}
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">Pair</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Price</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Volume</div>
          </div>
        </div>
        {hotMarkets?.map((x, index) => (
          <div
            className={cn(styles.row, styles.row_currency)}
            key={index}
            onClick={() => handleChangeCurency(x)}
          >
            <div className={styles.col}>
              <div className={styles.line}>
                <button className={cn("favorite", styles.favorite)}>
                  <Icon name="star-outline" size="16" />
                </button>
                <div className={styles.info}>
                  {x.base_currency_code}
                  <span>/{x.quote_currency_code}</span>
                </div>
              </div>
            </div>
            <div className={styles.col}>
              {x.rate >= 0 && <div className={styles.positive}>{x.rate}</div>}
              {x.rate < 0 && <div className={styles.negative}>{x.rate}</div>}
            </div>
            <div className={styles.col}>{x.volume_24}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Currency;
