import React, { useState } from "react";
import cn from "classnames";

import { NavLink, withRouter, useLocation } from "react-router-dom";

import Breadcrumbs from "../Breadcrumbs";

import styles from "./Support.module.sass";

const navigation = [
  {
    title: "Help Center",
    url: "/help-center",
  },
  {
    title: "Contant Us",
    url: "/contact-us",
  },
  {
    title: "Create Account",
    url: "/sign-in",
  },
  {
    title: "ID Verification",
    url: "/id-verification",
  },
  {
    title: "Account Information",
    url: "/account-information",
  },
];

const Profile = ({ title, breadcrumbs, children }) => {
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);

  const activeLink = navigation.find((x) => pathname.includes(x.url));

  return (
    <div className={styles.profile}>
      <div className={styles.head}>
        <div className={cn("container", styles.container)}>
          <h2 className={cn("h2", styles.title)}>{title}</h2>
          <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.sidebar}>
            <div className={cn(styles.dropdown, { [styles.active]: visible })}>
              <div className={styles.top} onClick={() => setVisible(!visible)}>
                {activeLink.title}
              </div>
              <div className={styles.group}>
                <div className={styles.menu}>
                  {navigation.map((item, index) => (
                    <NavLink
                      className={cn(styles.link, {
                        [styles.separator]: item.separator,
                      })}
                      activeClassName={styles.active}
                      key={index}
                      to={item.url}
                      exact
                      onClick={() => setVisible(false)}
                    >
                      {item.title}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.wrapper}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Profile);
