import { useForm, Controller } from "react-hook-form";
import React, { useState } from "react";
import { Range, getTrackBackground } from "react-range";
import cn from "classnames";

import Icon from "src/components/Icon";
import { ORDER_BUY, ORDER_SELL } from "src/constants/message";
import { FormProvider } from "src/common/components/hook-form";
import LoadingButton from "src/components/LoadingButton";
import { useToast } from "src/hooks/useToast";
import { useCreateOrder } from "src/hooks/useExchange";

import styles from "./Action.module.sass";

const defaultForm = { amount: "", price: "", total: "" };

const Action = ({
  title,
  content,
  price,
  classButton,
  buttonText,
  baseCurrency,
  quoteCurrency,
  balance,
  side,
}) => {
  const [values, setValues] = useState([0]);

  const stepPrice = 10;
  const minPrice = 0;
  const maxPrice = 100;

  const methods = useForm({ defaultValues: defaultForm });
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    watch,
    setValue,
    reset,
  } = methods;

  const watchAmount = watch("amount", 0);
  const watchPrice = watch("price", 0);

  const { toastSuccess, toastError } = useToast();
  const { mutateAsync: createOrder } = useCreateOrder({
    symbol: `${baseCurrency}${quoteCurrency}`,
  });
  const transactionSubmit = async (values) => {
    if (balance < values.amount) {
      toastError({
        title: "Error",
        description: "Balance is not enough",
      });
      return;
    }

    const payload = {
      price: values.price,
      quantity: values.amount,
      symbol: `${baseCurrency}${quoteCurrency}`,
      side: side,
      type: "LIMIT",
    };

    await createOrder(payload);
    // Message
    const message = {
      BUY: ORDER_BUY,
      SELL: ORDER_SELL,
    };
    toastSuccess({
      title: message[side].title,
      description: message[side].message({
        amount: values.amount,
        baseCurrency,
        quoteCurrency,
      }),
    });
    resetForm();
  };

  const handleChangeRange = (values) => {
    setValues(values);

    if (!watchPrice) {
      return;
    }

    const isBuy = side === "BUY";
    const isSell = side === "SELL";

    if (values[0] > 0 && isBuy) {
      const amount = (values[0] / 100) * (balance / watchPrice);
      setValue("amount", amount);
      setValue("total", amount * watchPrice);
    }

    if (values[0] > 0 && isSell) {
      const amount = (values[0] / 100) * balance;
      setValue("amount", amount);
      setValue("total", amount * watchPrice);
    }
  };

  const handleChangeAmount = (e) => {
    setValues([0]);
    if (e.target.value && watchPrice) {
      setValue(
        "total",
        `${parseFloat(e.target.value) * parseFloat(watchPrice)}`
      );
    }
  };

  const handleChangePrice = (e) => {
    setValues([0]);
    if (e.target.value && watchAmount) {
      setValue(
        "total",
        `${parseFloat(watchAmount) * parseFloat(e.target.value)}`
      );
    }
  };

  const resetForm = () => {
    reset(defaultForm);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(transactionSubmit)}>
      <div className={styles.head}>
        <div className={styles.title}>{title}</div>
        <div className={styles.counter}>
          <Icon name="wallet" size="16" /> {content}
        </div>
      </div>
      {price && (
        <label className={styles.field}>
          <div className={styles.label}>Price</div>
          <Controller
            name="price"
            control={control}
            render={({ field: { onChange, value } }) => (
              <input
                className={styles.input}
                type="number"
                name="price"
                required
                value={value}
                onChange={(e) => {
                  onChange(e);
                  handleChangePrice(e);
                }}
              />
            )}
          />

          <div className={styles.currency}>{quoteCurrency}</div>
        </label>
      )}
      {/* {stop && (
        <label className={styles.field}>
          <div className={styles.label}>Stop</div>
          <input className={styles.input} type="text" name="stop" required />
          <div className={styles.currency}>{quoteCurrency}</div>
        </label>
      )}
      {limit && (
        <label className={styles.field}>
          <div className={styles.label}>Limit</div>
          <input className={styles.input} type="text" name="limit" required />
          <div className={styles.currency}>{baseCurrency}</div>
        </label>
      )} */}
      <label className={styles.field}>
        <div className={styles.label}>Amount</div>
        <Controller
          name="amount"
          control={control}
          render={({ field: { onChange, value } }) => (
            <input
              className={styles.input}
              type="number"
              name="amount"
              required
              value={value}
              onChange={(e) => {
                onChange(e);
                handleChangeAmount(e);
              }}
            />
          )}
        />
        <div className={styles.currency}>{baseCurrency}</div>
      </label>
      <Range
        values={values}
        step={stepPrice}
        min={minPrice}
        max={maxPrice}
        onChange={(values) => handleChangeRange(values)}
        renderMark={({ props, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "6px",
              width: "2px",
              marginTop: "-2px",
              borderRadius: "1px",
              backgroundColor:
                index * stepPrice < values[0] ? "#3772FF" : "#E6E8EC",
            }}
          />
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "2px",
                width: "100%",
                borderRadius: "1px",
                background: getTrackBackground({
                  values,
                  colors: ["#3772FF", "#E6E8EC"],
                  min: minPrice,
                  max: maxPrice,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "18px",
              width: "18px",
              borderRadius: "50%",
              backgroundColor: "#F4F5F6",
              border: "4px solid #777E90",
              boxShadow: "0px 8px 16px -8px rgba(15, 15, 15, 0.2)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-27px",
                color: "#FCFCFD",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "16px",
                fontFamily: "Poppins",
                padding: "2px 6px",
                borderRadius: "6px",
                backgroundColor: "#777E90",
              }}
            >
              {values[0].toFixed(0)}%
            </div>
          </div>
        )}
      />
      <label className={styles.field}>
        <div className={styles.label}>Total</div>
        <Controller
          name="total"
          control={control}
          render={({ field: { value } }) => (
            <input
              className={styles.input}
              type="text"
              name="total"
              required
              value={value}
              readOnly
            />
          )}
        />
        {side === "BUY" && (
          <div className={styles.currency}>{quoteCurrency}</div>
        )}
        {side === "SELL" && (
          <div className={styles.currency}>{quoteCurrency}</div>
        )}
      </label>
      <LoadingButton
        classes={cn(classButton, styles.button)}
        type="submit"
        buttonText={buttonText}
        loading={isSubmitting}
      />
    </FormProvider>
  );
};

export default Action;
