import React from "react";
import cn from "classnames";

import styles from "../OrderBook.module.sass";
import { formatNumber, isBigNumber } from "../../../../utils";

const OrderRow = ({ order }) => {
  const numDecimals = isBigNumber(order.volume) ? 0 : 3;

  return (
    <div
      className={cn(
        styles.item,
        { [styles.positive]: order.positive },
        { [styles.negative]: order.negative }
      )}
    >
      <div className={styles.price}>{formatNumber(order.price)}</div>
      <div className={styles.amount}>
        {formatNumber(order.volume, numDecimals)}
      </div>
      <div className={styles.total}>
        {formatNumber(order.price * order.volume, numDecimals)}
      </div>
      <div
        className={styles.line}
        // TODO: return percent from BE
        style={{ width: `${"" + Math.random() * 100}%` }}
      ></div>
    </div>
  );
};

export default OrderRow;
