import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { formatter } from "../../../utils";

import styles from "./AssetBalances.module.sass";
import Deposit from "./Deposit";

const AssetBalances = ({ usdPrices }) => {
  const [balancesTransformed, setBalanceTransformed] = useState([]);

  const balances = useSelector((rootState) => rootState.balance.balances);

  const cal_price = (amount, asset) => {
    const usdPrice = usdPrices?.find((u) => u.short_name === asset);
    return formatter.format(amount * (usdPrice?.USD || 0));
  };
  const transformBalances = () => {
    if (!balances) return;

    let balancesArr = [];
    Object.keys(balances).forEach((k) => {
      balancesArr.push({
        id: balances[k],
        currency: balances[k].currency,
        content: balances[k].currency,
        icon: `/images/content/currency/${balances[k].currency}.svg`,
        currencyOrder: "N/A",
        priceOrder: "N/A",
        currencyAvailable: balances[k].available.toFixed(8),
        priceAvailable: `${cal_price(
          balances[k].available,
          balances[k].currency
        )}`,
        currencyTotal: balances[k].balance.toFixed(8),
        priceTotal: `${cal_price(balances[k].balance, balances[k].currency)}`,
      });
    });
    setBalanceTransformed(balancesArr);
  };

  useEffect(() => {
    transformBalances();
  }, [balances]);

  return (
    <div className={styles.wrap}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Asset</div>
          <div className={styles.col}>On orders</div>
          <div className={styles.col}>Available balance</div>
          <div className={styles.col}>Total balance</div>
          <div className={styles.col}>Action</div>
        </div>
        {balancesTransformed.map((x, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.col}>
              <div className={styles.currency}>
                <div className={styles.icon}>
                  <img src={x.icon} alt="Currency" />
                </div>
                <div className={styles.details}>
                  <div className={styles.info}>{x.currency}</div>
                  <div className={styles.text}>{x.content}</div>
                </div>
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.info}>{x.currencyOrder}</div>
              <div className={styles.text}>{x.priceOrder}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.info}>{x.currencyAvailable}</div>
              <div className={styles.text}>{x.priceAvailable}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.info}>{x.currencyTotal}</div>
              <div className={styles.text}>{x.priceTotal}</div>
            </div>
            <div className={styles.col}>
              <Deposit assetCode={x.currency} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssetBalances;
