export enum RoutePath {
  HOME = "/",
  MARKET = "/market",
  LEARN_CRYPTO = "/learn-crypto",
  LEARN_CRYPTO_DETAILS = "/learn-crypto-details",
  CONTACT = "/contact",
  NOTIFICATIONS = "/notifications",
  ACTIVITY = "/activity",
  EXCHANGE = "/exchange/:symbol",
  WALLET_OVERVIEW = "/wallet-overview",
  WALLET_OVERVIEW_DETAILS = "/wallet-overview/:id",
  WALLET_MARGIN = "/wallet-margin",
  FIAT_AND_SPOT = "/fiat-and-spot",
  PROFILE_INFO = "/profile-info",
  DEPOSIT_FIAT = "/deposit-fiat",
  BUY_CRYPTO = "/buy-crypto",
  SELL_CRYPTO = "/sell-crypto",
  API_KEYS = "/api-keys",
  SESSIONS_AND_LOGIN_HISTORY = "/sessions-and-login-history",
  TWO_FA = "/2fa",
  CHANGE_PASSWORD = "/change-password",
  SIGN_IN = "/sign-in",
  SIGN_UP = "/sign-up",
  FORGOT_PASSWORD = "/forgot-password",
  PAGELIST = "/pagelist",
  PRIVACY_POLICY = "/privacy",
  ABOUT = "/about",
  CAREERS = "/careers",
  DEVELOPER_TOOLS = "/developer-tools",
  HELP_CENTER = "/help-center",
  CONTACT_US = "/contact-us",
  ID_VERIFICATION = "/id-verification",
  ACCOUNT_INFORMATION = "/account-information",
  USER_AGREEMENT = "/user-agreement",
  AML_CFT = "/aml-cft",
  LICENSES = "/licenses",
  FEES = "/fees",
}
