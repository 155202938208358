export const EXCHANGE_PATH = {
  CREATE_ORDER: "/market/order/",
  CANCEL_ORDER: "/market/cancelorder/",
  GET_HISTORIES: "/market/settled/",
  GET_OPEN_ORDER: "/market/my/orderbook/",
  GET_ORDER_BOOK: "/market/orderbook/",
};

export const MARKET_PATH = {
  QUOTE: "/quotes/candles/simple/",
};

export const WALLET_PATH = {
  TRANSACTIONS: "/currency/histories/",
  GET_DEPOSIT_ADDRESS: "/currency/depositaddress/",
  WITHDRAW_REQUEST: "/currency/withdraw/request/",
  WITHDRAW_CONFIRM: "/currency/withdraw/confirm/",
};

export const AUTH_PATH = {
  REGISTER: "/user/onboarding/register/",
};

export const USER_PATH = {
  SESSIONS: "/user/session/list/",
};

export const FIAT_PATH = {
  PRICE_FEES: "fiat/price/all/",
};

export const CMS_PATH = {
  ABOUT: "companies/1",
  CAREERS: "companies/2",
  DEVELOPER_TOOLS: "companies/3",
  HELP_CENTER: "supports/1",
  CONTACT_US: "supports/2",
  ID_VERIFICATION: "supports/4",
  ACCOUNT_INFORMATION: "supports/5",
  POLICY: "/terms/1",
  USER_AGREEMENT: "/terms/2",
  AML_CFT: "/terms/3",
  LICENSES: "/terms/4",
  FEES: "/terms/5",
};
