import React, { useEffect, useState } from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import Icon from "../../../components/Icon";
import apiClient from "../../../middlewares/api-client";

import styles from "./PaymentDetails.module.sass";

const PaymentDetails = ({ paymentAmount }) => {
  const userInfo = useSelector((rootState) => rootState.user?.userInfo);
  const [paymentDetail, setPaymentDetail] = useState([]);
  useEffect(() => {
    setPaymentDetail([
      {
        title: "Account name",
        content: `${userInfo.first_name} ${userInfo.last_name}`,
      },
      {
        title: "Address",
        content: `${userInfo.street1} ${userInfo.city} ${userInfo.country.country_name}`,
      },
      {
        title: "Payment method",
        content: "Kibramoa",
      },
      {
        title: "Amount",
        content: `${paymentAmount.symbol} ${paymentAmount.amount}`,
      },
      {
        title: "USD amount",
        content: `$ ${paymentAmount.usd_amount}`,
      },
    ]);
  }, [userInfo, paymentAmount]);

  const handleDepositFiat = async () => {
    const depositResponse = await apiClient.post("/fiat/deposit/", {
      amount: parseInt(paymentAmount.amount),
      currency_code: paymentAmount.currency,
    });
    if (
      depositResponse?.status === 200 &&
      depositResponse.data.status === "success"
    ) {
      window.open(
        depositResponse?.data?.message.cashierUrl,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      // TODO: handle error here
      console.log(depositResponse);
    }
    // TODO: return to history page after open new deposit tab
  };

  return (
    <div className={styles.item}>
      <div className={styles.title}>Payment details</div>
      <div className={styles.info}>Bank account</div>
      <div className={styles.list}>
        {paymentDetail.map((x, index) => (
          <div className={styles.line} key={index}>
            <div className={styles.subtitle}>{x.title}</div>
            <div className={styles.details}>
              <div className={styles.content}>{x.content}</div>
              <button className={styles.copy}>
                <Icon name="copy" size="24" />
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* <div className={styles.info}>Reference code</div>
      <div className={styles.text}>
        You MUST include the Reference Code in your deposit in order to credit
        your account! <br></br>Reference Code:
      </div>
      <div className={styles.code}>BLUTUKHY34PB</div> */}
      {/* <div className={styles.btns}> */}
      {/* <Link
          className={cn("button-stroke", styles.button)}
          to="/wallet-overview"
        >
          Deposit Fiat
        </Link>
         */}
      <div className={styles.btns}>
        <button
          className={cn("button", styles.button)}
          onClick={handleDepositFiat}
        >
          Deposit Fiat
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

export default PaymentDetails;
