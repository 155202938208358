import React, { useEffect, useState } from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import Wallet from "../../components/Wallet";

import { useRematchDispatch } from "../../hooks";

import { useGetUSDPrice } from "../../hooks/customHooks";
import { formatter } from "../../utils";

import AssetBalances from "./AssetBalances";
import AccountBalances from "./AccountBalances";
import styles from "./WalletOverview.module.sass";
// const optionsCurrency = ["USD", "EUR", "RUB"];

const WalletOverview = () => {
  // const [search, setSearch] = useState("");
  // const [currency, setCurrency] = useState(optionsCurrency[0]);
  const [btcUSDPrice, setBtcUSDPrice] = useState("");

  const { fetchBalance } = useRematchDispatch((dispatch) => ({
    fetchBalance: dispatch.balance.fetchBalance,
  }));

  const btcBalance = useSelector((rootState) => rootState.balance?.btcBalance);

  const { data: usdPrices } = useGetUSDPrice();

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  useEffect(() => {
    if (btcBalance && usdPrices) {
      const btcUsdPriceUnit = usdPrices.find((b) => b.short_name === "tBTC");
      const btcUsdPrice = btcBalance * btcUsdPriceUnit?.USD;
      setBtcUSDPrice(formatter.format(btcUsdPrice));
    }
  }, [btcBalance, usdPrices]);

  // const handleSubmit = (e) => {
  //   alert();
  // };

  return (
    <Wallet>
      <div className={styles.top}>
        <div className={styles.line}>
          <h4 className={cn("h4", styles.title)}>Overview</h4>
          <div className={styles.wrap}>
            {/* <form
              className={styles.form}
              action=""
              onSubmit={() => handleSubmit()}
            >
              <input
                className={styles.input}
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                name="search"
                placeholder="Search coin"
                required
              />
              <button className={styles.result}>
                <Icon name="search" size="20" />
              </button>
            </form> */}
            {/* <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={currency}
              setValue={setCurrency}
              options={optionsCurrency}
            /> */}
            {/* <button className={cn("button-black button-small", styles.button)}>
              Show balance
            </button> */}
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.info}>Total balance</div>
          <div className={styles.currency}>
            <div className={styles.number}>{btcBalance}</div>
            <div className={cn("category-green", styles.category)}>BTC</div>
          </div>
          <div className={styles.price}>{btcUSDPrice}</div>
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.head}>Account Balances</div>
          <div className={styles.body}>
            <AccountBalances
              btcBalance={[
                {
                  key: "fiat",
                  amount: btcBalance,
                  usdPrice: btcUSDPrice,
                },
              ]}
            />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.head}>Asset Balances</div>
          <div className={styles.body}>
            <AssetBalances usdPrices={usdPrices} />
          </div>
        </div>
        {/* <div className={styles.item}>
          <div className={styles.head}>Integrations</div>
          <div className={styles.body}>
            <Integrations />
          </div>
        </div> */}
      </div>
    </Wallet>
  );
};

export default WalletOverview;
