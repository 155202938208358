import cn from "classnames";
import React from "react";

import Icon from "src/components/Icon";

import styles from "./TextInput.module.sass";

export type TextInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  className?: string;
  classLabel?: string;
  classInput?: string;
  label?: string;
  empty?: boolean;
  view?: boolean;
  icon?: string;
  note?: string;
  error?: boolean;
  message?: string;
  onView?: () => void;
};

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      classLabel,
      classInput,
      label,
      empty,
      view,
      icon,
      note,
      onView,
      error,
      message,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          styles.field,
          { [styles.empty]: empty },
          { [styles.view]: view },
          { [styles.icon]: icon },
          className
        )}
      >
        {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
        <div className={styles.wrap}>
          <input
            className={cn(classInput, styles.input)}
            ref={ref}
            {...props}
          />
          {view && (
            <button
              className={styles.toggle}
              type="button"
              onClick={() => onView && onView()}
            >
              <Icon name="eye" size="24" />
            </button>
          )}
          {icon && (
            <div className={styles.preview}>
              <Icon name={icon} size="24" />
            </div>
          )}
          {note && <div className={styles.note}>{note}</div>}
          {error && <div className={styles.message}>{message}</div>}
        </div>
      </div>
    );
  }
);

export default TextInput;
