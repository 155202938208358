import { Models } from "@rematch/core";

import { common } from "./common.model";
import { user } from "./user.model";
import { wallet } from "./wallet.model";
import { market } from "./market.model";
import { balance } from "./balance.model";
import { fiat } from "./fiat.model";
import { asset } from "./asset.model";

export interface RootModel extends Models<RootModel> {
  common: typeof common;
  user: typeof user;
  wallet: typeof wallet;
  balance: typeof balance;
  market: typeof market;
  fiat: typeof fiat;
  asset: typeof asset;
}

export const models: RootModel = {
  common,
  user,
  wallet,
  balance,
  market,
  fiat,
  asset,
};
