import Company from "../../components/Company";

import Content from "./Content";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Careers",
  },
];

const Careers = () => {
  return (
    <Company title="Company" breadcrumbs={breadcrumbs}>
      <Content />
    </Company>
  );
};

export default Careers;
