import cn from "classnames";
import { Link } from "react-router-dom";
import { RoutePath } from "src/routes/path";

import styles from "./Success.module.sass";

export const Success = () => {
  return (
    <div className={cn("h3", styles.title)}>
      Register success{" "}
      <Link to={RoutePath.SIGN_IN} className={styles.navigation}>
        back to login
      </Link>
    </div>
  );
};

export default Success;
