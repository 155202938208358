import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

import styles from "./Toast.module.sass";

export interface ToastMessageProps {
  title?: string;
  description?: string;
  type?: "success" | "error" | "warning" | "info";
}

export const ToastMessage = ({
  title,
  description,
  type = "info",
}: ToastMessageProps) => {
  return (
    <div className={styles[type]}>
      <ErrorOutlinedIcon color="secondary" className={styles.icon} />
      <div className={styles.wrapper}>
        {title && <div className={styles.title}>{title}</div>}
        {description && <div>{description}</div>}
      </div>
    </div>
  );
};
