import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { useGetStore } from "src/hooks/useStore";
import { useRematchDispatch } from "src/hooks/useRematchDispatch";
import { useToast } from "src/hooks/useToast";
import {
  cancelOrder,
  createOrder,
  getHistories,
  getOpenOrders,
  getOrderBooks,
} from "src/api/exchange";
import { EXCHANGE_PATH } from "src/api/path";

type UseCreateOrderProps = {
  symbol: string;
};

type UseGetHistoryProps = {
  symbol: string;
  marketid: string;
};

type UseCancelOrderProps = {
  symbol: string;
};

type UseGetOrderBooksProps = {
  marketid: string;
};

type OrderBookItem = {
  price: string;
  volume: string;
};

export const useGetDataExchange = () => {
  const market = useGetStore("market");
  const balance = useGetStore("balance");

  const loading = useGetStore("loading");
  const marketLoading = _.get(loading, "models.market.loading", true);

  const currentMarket = _.get(market, "currentMarket", {});
  const balances = _.get(balance, "balances", {});
  const btcBalance = _.get(balance, "btcBalance", 0);

  const baseCurrencyId = _.get(currentMarket, "base_currency_id", "");
  const balanceExchange = _.get(balances, baseCurrencyId, {});
  const marketId = _.get(currentMarket, "id", "");

  const baseCurrencyCode = _.get(currentMarket, "base_currency_code", "");
  const quoteCurrencyCode = _.get(currentMarket, "quote_currency_code", "");

  const myOpenTrades = _.get(market, "myOpenTrades");

  return {
    currentMarket,
    balances,
    btcBalance,
    balanceExchange,
    loading: marketLoading,
    baseCurrencyCode,
    quoteCurrencyCode,
    myOpenTrades,
    symbol: `${baseCurrencyCode}${quoteCurrencyCode}`,
    marketId,
  };
};

export const useFetchDataExchange = () => {
  const { fetchMyOpenTrades } = useRematchDispatch((dispatch) => ({
    fetchMyOpenTrades: dispatch.market.fetchMyOpenTrades,
  }));

  return {
    fetchMyOpenTrades,
  };
};

const useReloadBalance = () => {
  const { fetchBalance } = useRematchDispatch((dispatch) => ({
    fetchBalance: dispatch.balance.fetchBalance,
  }));

  const reloadBalance = () => {
    fetchBalance();
  };

  return {
    reloadBalance,
  };
};

export const useCreateOrder = ({ symbol }: UseCreateOrderProps) => {
  const { toastError } = useToast();
  const { reloadBalance } = useReloadBalance();
  const queryClient = useQueryClient();

  return useMutation(createOrder, {
    onError: (error: any) => {
      const message = _.get(error, "message", "");
      toastError({ title: "Error", description: message });
    },
    onSuccess: () => {
      reloadBalance();
      queryClient.invalidateQueries([EXCHANGE_PATH.GET_OPEN_ORDER, symbol]);
    },
  });
};

export const useCancelOrder = ({ symbol }: UseCancelOrderProps) => {
  const { toastError } = useToast();
  const { reloadBalance } = useReloadBalance();
  const queryClient = useQueryClient();

  return useMutation(cancelOrder, {
    onError: (error: any) => {
      const message = _.get(error, "message", "");
      toastError({ title: "Error", description: message });
    },
    onSuccess: () => {
      reloadBalance();
      queryClient.invalidateQueries([EXCHANGE_PATH.GET_OPEN_ORDER, symbol]);
    },
  });
};

export const useGetHistories = ({ symbol, marketid }: UseGetHistoryProps) => {
  return useQuery(
    [EXCHANGE_PATH.GET_HISTORIES, symbol],
    () => getHistories(symbol, marketid),
    { enabled: !!(symbol && marketid) }
  );
};

export const useGetOpenOrders = ({ symbol, marketid }: UseGetHistoryProps) => {
  return useQuery(
    [EXCHANGE_PATH.GET_OPEN_ORDER, symbol],
    async () => {
      const response = await getOpenOrders(symbol, marketid);
      const buy = _.get(response, "buy") as any;
      const sell = _.get(response, "sell") as any;
      return [...buy, ...sell];
    },
    { enabled: !!(symbol && marketid) }
  );
};

export const useGetOrderBooks = ({ marketid }: UseGetOrderBooksProps) => {
  return useQuery(
    [EXCHANGE_PATH.GET_OPEN_ORDER, marketid],
    async () => {
      const response = await getOrderBooks(marketid);

      console.log(response);

      const buy = _.orderBy(
        _.get(response, "buy", []) as OrderBookItem[],
        ["price"],
        ["asc"]
      );
      const sell = _.orderBy(
        _.get(response, "sell", []) as OrderBookItem[],
        ["price"],
        ["asc"]
      );

      return {
        buy: [
          ...buy.map(({ price, volume }) => [Number(price), Number(volume)]),
        ],
        sell: [
          ...sell.map(({ price, volume }) => [Number(price), Number(volume)]),
        ],
      };
    },
    { enabled: !!marketid }
  );
};
