import React, { useState } from "react";
import cn from "classnames";

import { Link } from "react-router-dom";

import { AreaChart, Area, ResponsiveContainer } from "recharts";
import { formatNumber } from "src/utils";
import {
  useGetSimpleQuotes,
  cal24hRate,
  useGetMarkets,
} from "src/hooks/useMarket";
import { transformQuotes } from "src/utils";

import _ from "lodash";

import Dropdown from "../../../components/Dropdown";

import styles from "./Trend.module.sass";

const navigation = [
  "All",
  // "DeFi",
  // "Innovation",
  // "POS",
  // "NFT",
  // "POW",
  // "Storage",
];

const Learn = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(navigation[0]);
  const { hotMarkets } = useGetMarkets();
  const marketIds = _.map(hotMarkets, (market) => market.id);
  const imgBasePath = "/images/content/currency/";
  const quotesQueries = useGetSimpleQuotes({ marketIds });

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.line}>
          <h2 className={cn("h2", styles.title)}>Market trend</h2>
          <Link className={cn("button-stroke", styles.button)} to="/market">
            View more
          </Link>
        </div>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        <div className={styles.dropdown}>
          <Dropdown
            className={styles.dropdown}
            value={sorting}
            setValue={setSorting}
            options={navigation}
          />
        </div>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Price</div>
            <div className={styles.col}>24h change</div>
            <div className={styles.col}>Chart(24h)</div>
            <div className={styles.col}>Trade</div>
          </div>
          {hotMarkets &&
            hotMarkets?.map((x, index) => (
              <Link className={styles.row} to={"/market"} key={index}>
                <div className={styles.col}>{index + 1}</div>
                <div className={styles.col}>
                  <div className={styles.item}>
                    <div className={styles.icon}>
                      <img
                        src={imgBasePath + x.base_currency_code + ".svg"}
                        alt="Currency"
                      />
                    </div>
                    <div className={styles.details}>
                      <span className={styles.subtitle}>
                        {x.base_currency_code}
                      </span>
                      {/* <span className={styles.currency}>{markets[x].currency}</span> */}
                    </div>
                  </div>
                </div>
                <div className={styles.col}>
                  {formatNumber(x.last_price, 8)}
                </div>
                {/* <div className={styles.positive}>{x.positive}</div> */}
                <div className={styles.col}>
                  {x.change_24 >= 0 && (
                    <div className={styles.positive}>
                      +{cal24hRate(x.last_price, x.change_24)}%
                    </div>
                  )}
                  {x.change_24 < 0 && (
                    <div className={styles.negative}>
                      {cal24hRate(x.last_price, x.change_24)}%
                    </div>
                  )}
                </div>
                <div className={styles.col}>
                  <div className={styles.chart}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        width={500}
                        height={400}
                        data={transformQuotes(
                          _.get(quotesQueries, `[${index}].data`, [])
                        )}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <defs>
                          <linearGradient
                            id="colorPrice"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="#45B36B"
                              stopOpacity={0.6}
                            />
                            <stop
                              offset="95%"
                              stopColor="#45B36B"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <Area
                          type="monotone"
                          dataKey="price"
                          stroke="#58BD7D"
                          fillOpacity={1}
                          fill="url(#colorPrice)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className={styles.col}>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                  >
                    Trade
                  </button>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Learn;
