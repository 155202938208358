interface LimitOrder {
  amount: number;
  baseCurrency: string;
  quoteCurrency: string;
}

export const ORDER_SELL = {
  title: "Limmit Sell Order Created",
  message: ({ amount, baseCurrency, quoteCurrency }: LimitOrder) =>
    `Summitted exchange limit sell order for ${amount} ${baseCurrency} by using ${quoteCurrency}`,
};

export const CANCEL_ORDER_SELL = {
  title: "Limmit Sell Order Canceled",
  message: ({ amount, baseCurrency, quoteCurrency }: LimitOrder) =>
    `Canceled exchange limit sell order for ${amount} ${baseCurrency} by using ${quoteCurrency}`,
};

export const ORDER_BUY = {
  title: "Limmit Buy Order Created",
  message: ({ amount, baseCurrency, quoteCurrency }: LimitOrder) =>
    `Summitted exchange limit buy order for ${amount} ${baseCurrency} by using ${quoteCurrency}`,
};

export const CANCEL_ORDER_BUY = {
  title: "Limmit Buy Order Canceled",
  message: ({ amount, baseCurrency, quoteCurrency }: LimitOrder) =>
    `Canceled exchange limit buy order for ${amount} ${baseCurrency} by using ${quoteCurrency}`,
};
