import Legal from "../../components/Legal";

import Content from "./Content";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Licenses",
  },
];

const Licenses = () => {
  return (
    <Legal title="Licenses" breadcrumbs={breadcrumbs}>
      <Content />
    </Legal>
  );
};

export default Licenses;
