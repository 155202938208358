import { useEffect, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import LoadingButton from "src/components/LoadingButton";
import { useToast } from "src/hooks/useToast";
import Icon from "src/components/Icon";
import { currencySymbol } from "src/utils";
import { useRematchDispatch } from "src/hooks";
import {
  useMutationAssetPrice,
  useMutationFiatRate,
} from "src/hooks/customHooks";
import Dropdown from "src/components/Dropdown";

import styles from "./EnterAmount.module.sass";

const priceVariants = ["50.00", "100.00", "200.00", "500.00"];

const EnterAmount = ({ goNext, goBack }) => {
  const [currency, setCurrency] = useState("USD");
  const [amount, setAmount] = useState("50.00");
  const [currenciesList, setCurrenciesList] = useState([]);
  const [assetAmount, setAssetAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fiatDispatch = useRematchDispatch((dispatch) => dispatch.fiat);

  const currencies = useSelector((rootState) => rootState.fiat?.currencies);
  const asset = useSelector((rootState) => rootState.fiat?.asset);

  const priceMutation = useMutationAssetPrice();
  const rateMutation = useMutationFiatRate();
  const { toastError } = useToast();

  useEffect(() => {
    const _currencies = currencies?.map((c) => c.currency);
    if (_currencies) {
      setCurrenciesList(_currencies || []);
      setCurrency(_currencies[0]);
      rateMutation.mutate({ currency: _currencies[0], amount: 1 });
    }
  }, [currencies]);

  useEffect(() => {
    fiatDispatch.fetchCurrencies();
    priceMutation.mutate(asset);
  }, []);

  useEffect(() => {
    if (
      rateMutation.status === "success" &&
      priceMutation.status === "success"
    ) {
      const _transactFee = rateMutation.data.fees.transaction_fee * amount;
      const _convertFee = rateMutation.data.fees.convert_fee * amount;
      const _amount =
        (amount - _transactFee - _convertFee) * rateMutation.data.rate;
      const _assetAmount = _amount / priceMutation.data.price;
      setAssetAmount(_assetAmount.toFixed(4));
    }
  }, [rateMutation.status, priceMutation.status, amount]);

  const handleCurrencyChange = (value) => {
    setCurrency(value);
    rateMutation.mutate({ currency: value, amount: 1 });
  };

  const handleGoNext = async () => {
    try {
      setIsLoading(true);
      fiatDispatch.setAmount(amount);
      fiatDispatch.setAsset(asset);
      fiatDispatch.setAssetAmount(assetAmount);
      fiatDispatch.setCurrency(currency);
      const _amount = parseInt(parseFloat(amount));
      await fiatDispatch.fetchPaymentOptions({
        amount: _amount,
        asset,
        currency,
      });
      setIsLoading(false);
      goNext();
    } catch (error) {
      setIsLoading(false);
      toastError({ title: "Error", description: error.message });
    }
  };

  return (
    <div>
      <div className={styles.title}>Select currency and payment method</div>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>Select currency</div>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={currency}
            setValue={handleCurrencyChange}
            options={currenciesList}
          />
        </div>
      </div>
      <div className={styles.control}>
        <button className={styles.back} onClick={goBack}>
          <Icon name="arrow-prev" size="14" />
          Enter amount
        </button>
        <div className={styles.money}>
          Buying {asset}
          <img src={`/images/content/currency/${asset}.svg`} alt="Coin" />
        </div>
      </div>
      <div className={styles.payment}>
        <div className={cn("h4", styles.sign)}>{currencySymbol(currency)}</div>
        <div className={styles.field}>
          <div className={styles.value}>{amount}</div>
          <input
            className={styles.input}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.price}>
        {assetAmount} <span>{asset}</span>
      </div>
      <div className={styles.variants}>
        {priceVariants.map((x, index) => (
          <button
            className={cn("button-stroke button-small", styles.button)}
            type="button"
            key={index}
            onClick={() => setAmount(x.replace(currencySymbol(currency), ""))}
          >
            {`${x}${currencySymbol(currency)}`}
          </button>
        ))}
      </div>
      <div className={styles.btns}>
        <LoadingButton
          className={cn("button", styles.button)}
          onClick={handleGoNext}
          buttonText="Continue"
          loading={isLoading}
          disabled={false}
        />
      </div>
    </div>
  );
};

export default EnterAmount;
