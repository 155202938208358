import Support from "../../components/Support";

import Content from "./Content";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Contact Us",
  },
];

const ContactUs = () => {
  return (
    <Support title="Contact Us" breadcrumbs={breadcrumbs}>
      <Content />
    </Support>
  );
};

export default ContactUs;
