import React, { useEffect, useRef, useState } from "react";

import "./style.css";
import { createChart, CrosshairMode, ColorType } from "lightweight-charts";

import { useGetQuote } from "../../hooks/customHooks";
// import apiClient from '../../middlewares/api-client'
import Loader from "../Loader";

import styles from "./Chart.module.sass";
const historyTime = 172800;

export const Theme = {
  DARK: {
    backgroundColor: "#181b26",
    textColor: "rgba(255, 255, 255, 0.9)",
    candle: {
      upColor: "#4bffb5",
      downColor: "#ff4976",
      borderDownColor: "#ff4976",
      borderUpColor: "#4bffb5",
      wickDownColor: "#838ca1",
      wickUpColor: "#838ca1",
    },
  },
  LIGHT: {
    backgroundColor: "white",
    textColor: "black",
    candle: {
      upColor: "#089a81",
      downColor: "#f23645",
      borderDownColor: "#f23645",
      borderUpColor: "#089a81",
      wickDownColor: "#838ca1",
      wickUpColor: "#838ca1",
    },
  },
};

export default function Chart({ height, theme, resolution, marketId }) {
  const chartContainerRef = useRef();
  const chart = useRef();
  const resizeObserver = useRef();
  const currentDate = Math.floor(Date.now() / 1000);
  const [volumeData, setVolumeData] = useState(null);
  const [isLoading, setLoading] = useState(true);

  // const [chart, setPriceData] = useState(null)

  const { data: priceData, refetch } = useGetQuote(
    marketId,
    resolution,
    `${currentDate - historyTime}`,
    `${currentDate}`
  );

  const generateVolumeData = () => {
    if (priceData) {
      const _volumeData = priceData.map((e) => {
        const color =
          e.open < e.close
            ? `${theme.candle.upColor}4d`
            : `${theme.candle.downColor}4d`;
        return { time: e.time, value: e.volume, color };
      });
      setVolumeData(_volumeData);
    }
  };

  useEffect(() => {
    generateVolumeData();
  }, [priceData]);

  useEffect(() => {
    refetch();
  }, [marketId]);

  useEffect(() => {
    if (!priceData || !volumeData) {
      return;
    }
    chart?.current?.remove();
    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef?.current?.clientWidth,
      height: height,
      layout: {
        background: { type: ColorType.Solid, color: theme.backgroundColor },
        textColor: theme.textColor,
      },
      grid: {
        vertLines: {
          color: "#334158",
        },
        horzLines: {
          color: "#334158",
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      priceScale: {
        borderColor: "#485c7b",
      },
      timeScale: {
        borderColor: "#485c7b",
      },
    });
    const candleSeries = chart?.current?.addCandlestickSeries({
      upColor: theme.candle.upColor,
      downColor: theme.candle.downColor,
      borderDownColor: theme.candle.borderDownColor,
      borderUpColor: theme.candle.borderUpColor,
      wickDownColor: theme.candle.wickDownColor,
      wickUpColor: theme.candle.wickUpColor,
    });

    const volumeSeries = chart?.current?.addHistogramSeries({
      color: "#182233",
      lineWidth: 2,
      priceFormat: {
        type: "volume",
      },
      overlay: true,
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
    });
    volumeSeries?.setData(volumeData);
    candleSeries?.setData(priceData);

    setLoading(false);
  }, [height, theme, priceData, volumeData, marketId]);

  // Resize chart on container resizes.
  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      chart?.current?.applyOptions({ width, height });
      setTimeout(() => {
        chart?.current?.timeScale().fitContent();
      }, 0);
    });

    resizeObserver.current.observe(chartContainerRef.current);

    return () => resizeObserver.current.disconnect();
  }, []);

  return (
    <div className={styles.wrapper} style={{ height }}>
      {isLoading && (
        <div className={styles.loading_wrapper}>
          <Loader />
        </div>
      )}
      <div ref={chartContainerRef} className="chart-container" />
    </div>
  );
}
