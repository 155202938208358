import { init, RematchDispatch, RematchRootState } from "@rematch/core";
import loadingPlugin, { ExtraModelsFromLoading } from "@rematch/loading";
import immerPlugin from "@rematch/immer";
import persistPlugin from "@rematch/persist";
import storage from "redux-persist/lib/storage";

import { models, RootModel } from "./models";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

type FullModel = ExtraModelsFromLoading<RootModel, { type: "full" }>;

export const store = init<RootModel, FullModel>({
  models,
  plugins: [
    immerPlugin(),
    loadingPlugin({ type: "full" }),
    persistPlugin(persistConfig),
  ],
  redux: {
    rootReducers: {
      RESET_APP: () => undefined,
    },
    devtoolOptions: {
      disabled: process.env.NODE_ENV === "production",
    },
  },
});

export type Store = typeof store;
export type RootDispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;
