import { createModel } from "@rematch/core";

import apiClient from "../../middlewares/api-client";

import { RootModel } from ".";

// eslint-disable-next-line @typescript-eslint/no-empty-interface

export interface Country {
  countryCode: string;
  id: number;
  countryName: string;
}
export interface Timezone {
  timezone: string;
  id: number;
}
export interface CommonState {
  countries: Array<Country>;
  timezones: Array<Timezone>;
}

const initState = {
  countries: [],
  timezones: [],
} as CommonState;

export const common = createModel<RootModel>()({
  state: initState,
  reducers: {
    reset() {
      return initState;
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    setTimezones(state, timezones) {
      state.timezones = timezones;
    },
  },
  effects: () => ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async fetchCountries() {
      const response = await apiClient.post("user/getcountries/", {});
      const { message: countries } = response.data || {};
      this.setCountries(countries);
    },
    async fetchCountryList(_: void, rootState) {
      if (rootState.common.countries?.length) {
        return;
      }
      const response = await apiClient.post("user/getcountries/", {});
      const { message: countries } = response.data || {};
      this.setCountries(countries);
    },
    async fetchTimezoneList(_: void, rootState) {
      if (rootState.common.timezones?.length) {
        return;
      }
      const response = await apiClient.post("user/gettimezones/", {});
      const { message: timezones } = response.data || {};
      this.setTimezones(timezones);
    },
  }),
});
