import apiClient from "src/middlewares/api-client";
import { Register } from "src/types/auth";

import { AUTH_PATH } from "./path";

export const registerAccount = (data: Register) => {
  return apiClient.post(AUTH_PATH.REGISTER, sanitizeRegisterAccount(data));
};

const sanitizeRegisterAccount = ({
  username,
  email,
  password,
  pincode,
}: Register) => ({
  username,
  email,
  password,
  pincode,
  // TODO get from cookie
  referred_by: "f1232d3-12ad23-b23e123-c12340-d12398",
});
