import axios from "axios";

const instance = axios.create({
  baseURL: "https://cms.byta.tech/",
});

instance.interceptors.response.use((response) => {
  // eslint-disable-next-line no-var
  var metaRobots = document.querySelector('meta[name="robots"]');
  if (!metaRobots) {
    metaRobots = document.createElement("meta");
    metaRobots.setAttribute("name", "robots");
    document.head.appendChild(metaRobots);
  }
  metaRobots.setAttribute("content", "noindex, nofollow");
  return response;
});

export default instance;
