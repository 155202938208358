import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Page from "src/components/Page";
import Home from "src/screens/Home";
import Market from "src/screens/Market";
import LearnCrypto from "src/screens/LearnCrypto";
import LearnCryptoDetails from "src/screens/LearnCryptoDetails";
import Contact from "src/screens/Contact";
import Notifications from "src/screens/Notifications";
import Activity from "src/screens/Activity";
import Exchange from "src/screens/Exchange";
import WalletOverview from "src/screens/WalletOverview";
import WalletOverviewDetails from "src/screens/WalletOverviewDetails";
import WalletMargin from "src/screens/WalletMargin";
import FiatAndSpot from "src/screens/FiatAndSpot";
import DepositFiat from "src/screens/DepositFiat";
import BuyCrypto from "src/screens/BuyCrypto";
import SellCrypto from "src/screens/SellCrypto";
import ProfileInfo from "src/screens/ProfileInfo";
import ApiKeys from "src/screens/ApiKeys";
import SessionsAndLoginHistory from "src/screens/SessionsAndLoginHistory";
import TwoFa from "src/screens/TwoFa";
import ChangePassword from "src/screens/ChangePassword";
import SignIn from "src/screens/SignIn";
import SignUp from "src/screens/SignUp";
import ForgotPassword from "src/screens/ForgotPassword";
import PageList from "src/screens/PageList";
import Policy from "src/screens/Policy";
import ProtectedRoute from "src/routes/ProtectedRoute";
import { useGetUserInfo } from "src/hooks/useUser";
import { RoutePath } from "src/routes/path";
import About from "src/screens/About";
import Careers from "src/screens/Careers";
import DeveloperTools from "src/screens/DeveloperTools";
import HelpCenter from "src/screens/HelpCenter";
import ContactUs from "src/screens/ContactUs";
import IdVerification from "src/screens/IdVerification";
import AccountInformation from "src/screens/AccountInformation";
import UserAgreement from "src/screens/UserAgreement";
import AmlCft from "src/screens/AmlCft";
import Licenses from "src/screens/Licenses";
import Fees from "src/screens/Fees";

const publicRoutes = [
  {
    exact: true,
    path: RoutePath.HOME,
    component: Home,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.MARKET,
    component: Market,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.LEARN_CRYPTO,
    component: LearnCrypto,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.LEARN_CRYPTO_DETAILS,
    component: LearnCryptoDetails,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.CONTACT,
    component: Contact,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.EXCHANGE,
    component: Exchange,
    layout: Page,
    layoutProps: { headerWide: true, footerHide: true },
  },
  {
    exact: true,
    path: RoutePath.FIAT_AND_SPOT,
    component: FiatAndSpot,
    layout: Page,
    layoutProps: { headerWide: true, footerHide: true },
  },
  {
    exact: true,
    path: RoutePath.DEPOSIT_FIAT,
    component: DepositFiat,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.BUY_CRYPTO,
    component: BuyCrypto,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.SELL_CRYPTO,
    component: SellCrypto,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.SIGN_IN,
    component: SignIn,
    layout: Page,
    layoutProps: { headerHide: true, headerWide: true, footerHide: true },
  },
  {
    exact: true,
    path: RoutePath.SIGN_UP,
    component: SignUp,
    layout: Page,
    layoutProps: { headerHide: true, headerWide: true, footerHide: true },
  },
  {
    exact: true,
    path: RoutePath.FORGOT_PASSWORD,
    component: ForgotPassword,
    layoutProps: { headerHide: true, headerWide: true, footerHide: true },
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.PAGELIST,
    component: PageList,
    layoutProps: { headerHide: true, headerWide: true, footerHide: true },
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.PRIVACY_POLICY,
    component: Policy,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.ABOUT,
    component: About,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.CAREERS,
    component: Careers,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.DEVELOPER_TOOLS,
    component: DeveloperTools,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.HELP_CENTER,
    component: HelpCenter,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.CONTACT_US,
    component: ContactUs,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.ID_VERIFICATION,
    component: IdVerification,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.ACCOUNT_INFORMATION,
    component: AccountInformation,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.USER_AGREEMENT,
    component: UserAgreement,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.AML_CFT,
    component: AmlCft,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.LICENSES,
    component: Licenses,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.FEES,
    component: Fees,
    layout: Page,
  },
];

const protectedRoutes = [
  {
    exact: true,
    path: RoutePath.ACTIVITY,
    component: Activity,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.WALLET_OVERVIEW,
    component: WalletOverview,
    layout: Page,
    layoutProps: { headerWide: true, footerHide: true },
  },
  {
    exact: true,
    path: RoutePath.WALLET_OVERVIEW_DETAILS,
    component: WalletOverviewDetails,
    layout: Page,
    layoutProps: { headerWide: true, footerHide: true },
  },
  {
    exact: true,
    path: RoutePath.PROFILE_INFO,
    component: ProfileInfo,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.SESSIONS_AND_LOGIN_HISTORY,
    component: SessionsAndLoginHistory,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.TWO_FA,
    component: TwoFa,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.API_KEYS,
    component: ApiKeys,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.WALLET_MARGIN,
    component: WalletMargin,
    layout: Page,
    layoutProps: { headerWide: true, footerHide: true },
  },
  {
    exact: true,
    path: RoutePath.CHANGE_PASSWORD,
    component: ChangePassword,
    layout: Page,
  },
  {
    exact: true,
    path: RoutePath.NOTIFICATIONS,
    component: Notifications,
    layout: Page,
  },
];

function AppRoutes() {
  const { isAuth } = useGetUserInfo();

  return (
    <Router>
      <Switch>
        {publicRoutes.map((route, index) => (
          <Route
            exact
            path={route.path}
            render={() => (
              <route.layout {...route.layoutProps}>
                <route.component />
              </route.layout>
            )}
            key={index}
          />
        ))}
        {protectedRoutes.map((route, index) => (
          <ProtectedRoute
            exact
            path={route.path}
            isAuthenticated={isAuth}
            component={() => (
              <route.layout {...route.layoutProps}>
                <route.component />
              </route.layout>
            )}
            key={index}
          />
        ))}
      </Switch>
    </Router>
  );
}

export default AppRoutes;
