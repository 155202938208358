import { useEffect } from "react";
import { withRouter, useLocation, RouteComponentProps } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

import Header from "../Header";
import Footer from "../Footer";

import styles from "./Page.module.sass";

type Props = RouteComponentProps & {
  headerHide?: boolean;
  children?: React.ReactNode;
  footerHide?: boolean;
  headerWide?: boolean;
};

const Page: React.FC<Props> = ({
  headerHide,
  children,
  footerHide,
  headerWide,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, [pathname]);

  return (
    <>
      <div className={styles.page}>
        {!headerHide && <Header headerWide={headerWide} />}
        <div className={styles.inner}>{children}</div>
        {!footerHide && <Footer />}
      </div>
    </>
  );
};

export default withRouter(Page);
