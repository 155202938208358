import { useQuery, useMutation } from "@tanstack/react-query";
import { logout } from "src/api/authUtils";

import apiClient from "../middlewares/api-client";

interface RatePayload {
  currency: string;
  amount: number;
}
interface PaymentOptionPayload {
  amount: number;
  currency: string;
  asset: string;
}

export enum ServerStateKeysEnum {
  Assets = "Asset-assetData",
  BaseAssets = "BaseAsset-assetData",
  QuoteAssets = "QuoteAsset-assetData",
  Markets = "Market-marketData",
  AddressBook = "Addresses-addressesData",
  Network = "Network-networkData",
  TransactionHistory = "TransactionHistory-historiesData",
  Transactions = "Transaction-transactionData",
  USDPrice = "USDPrice-usdPriceData",
  AssetPrice = "AssetPrice",
  Quote = "Quote-quoteData",
}

export const fetchData = async (path: string, payload: object) => {
  const response = await apiClient
    .post(path, payload)
    .catch(async (error: any) => {
      if (
        error.message === "Not authenticated" ||
        error.message === "Permission deny"
      ) {
        logout();
      }
    });
  // @ts-ignore
  const { message: data } = response.data || {};

  return data;
};

export const useGetAssets = () => {
  return useQuery([ServerStateKeysEnum.Assets], async () => {
    const data = await fetchData("/market/assets/all/", {});

    return data;
  });
};

export const useGetQuoteAsset = () => {
  return useQuery([ServerStateKeysEnum.QuoteAssets], async () => {
    const data = await fetchData("/market/base_assets/", {});

    return data;
  });
};

export const useGetMarkets = () => {
  return useQuery([ServerStateKeysEnum.Markets], async () => {
    const data = await fetchData("/market/all/", {});

    return data;
  });
};

export const useGetTransactions = () => {
  return useQuery([ServerStateKeysEnum.Transactions], async () => {
    const data = await fetchData("/currency/merchant/transaction/all/", {});

    return data;
  });
};

export const useGetUSDPrice = () => {
  return useQuery([ServerStateKeysEnum.USDPrice], async () => {
    const data = await fetchData("/currency/profile/usdvalue/all/", {});

    return data;
  });
};

export const useGetQuote = (
  market: string,
  resolution: string,
  from: string,
  to: string
) => {
  return useQuery([ServerStateKeysEnum.Quote, resolution], async () => {
    const url = `quotes/candles/simple/${market}/${resolution}/${from}/${to}/`;
    const data = await fetchData(url, {});

    return data;
  });
};

export const useGetAssetPrice = (asset_code: string) => {
  return useQuery([ServerStateKeysEnum.AssetPrice], async () => {
    const payload = {
      asset_code: asset_code,
    };
    const data = await fetchData("/fiat/price/", payload);

    return data;
  });
};

export const useMutationAssetPrice = () => {
  return useMutation(async (asset_code: string) => {
    const payload = {
      asset_code: asset_code,
    };
    const data = await fetchData("/fiat/price/", payload);
    return data;
  });
};

export const useMutationFiatRate = () => {
  return useMutation(async (ratePayload: RatePayload) => {
    const payload = {
      from_currency: ratePayload.currency,
      to_currency: "USD",
      amount: ratePayload.amount,
    };
    const data = await fetchData("/fiat/rate/", payload);
    return data;
  });
};

export const useMutationPaymentOptions = () => {
  return useMutation(async (paymentOption: PaymentOptionPayload) => {
    const data = await fetchData("/fiat/payment-options/", paymentOption);
    return data;
  });
};
