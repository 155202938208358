import { createModel } from "@rematch/core";

import apiClient from "../../middlewares/api-client";

import { RootModel } from ".";

export interface MarketState {
  markets: undefined;
  hotMarkets: undefined;
  settled: undefined;
  myOpenTrades: undefined;
  orderBook: undefined;
  currentMarket: undefined;
}

const initState = {} as MarketState;
export const market = createModel<RootModel>()({
  state: initState,
  reducers: {
    update(state, payload) {
      state.markets = payload.markets;
      state.hotMarkets = payload.hotMarkets;
    },
    updateSettled(state, payload) {
      state.settled = payload.settled.slice(0, 25);
    },
    updateMyOpenTrades(state, payload) {
      const buys = payload.myOpenTrades?.buy || [];
      const sells = payload.myOpenTrades?.sell || [];
      buys?.map((d: any) => (d["side"] = "BUY"));
      sells?.map((s: any) => (s["side"] = "SELL"));
      state.myOpenTrades = buys.concat(sells);
    },
    updateOrderBook(state, payload) {
      // TODO: trim from BE
      state.orderBook = payload.orderBook;
    },
    updateCurrentMarket(state, payload) {
      state.currentMarket = payload.currentMarket;
    },
    reset() {
      return initState;
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  effects: () => ({
    async fetchAllMarkets() {
      const response = await apiClient.post("/market/all/", {});
      // @ts-ignore
      const { message: markets } = response.data || {};
      let hotMarkets = [];
      for (const k of Object.keys(markets)) {
        hotMarkets.push({
          ...markets[k],
        });
      }
      hotMarkets = hotMarkets.sort(
        (a: any, b): any => b.change_24 - a.change_24
      );
      this.update({ markets, hotMarkets });
    },
    async fetchSettled(symbol) {
      const response = await apiClient.post("/market/settled/", { symbol });
      // @ts-ignore
      const { message: settled } = response.data || {};

      this.updateSettled({ settled: settled.items });
    },
    async fetchMyOpenTrades(symbol) {
      const path = `market/my/orderbook/${symbol}/both/`;
      const response = await apiClient.post(path, {});
      // @ts-ignore
      const { message: myOpenTrades } = response.data || {};

      this.updateMyOpenTrades({ myOpenTrades: myOpenTrades });
    },
    async fetchOrderBook(marketid) {
      const response = await apiClient.post("/market/orderbook/", { marketid });
      // @ts-ignore
      const { message: orderBook } = response.data || {};

      this.updateOrderBook({ orderBook: orderBook });
    },
    async fetchCurrentMarket(symbol, rootState) {
      const markets = rootState.market.markets;
      if (markets) {
        const currentMarketID = Object.keys(markets)?.find(
          (k: any) =>
            // @ts-ignore
            markets[k].symbol === symbol
        );
        // @ts-ignore
        this.updateCurrentMarket({ currentMarket: markets[currentMarketID] });
      }
    },
  }),
});
