import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";

import Form from "../Form";
import Icon from "../Icon";
import Image from "../Image";

import styles from "./Footer.module.sass";

const menu = [
  {
    title: "Exchange",
    url: "/exchange/tBTCUSDT",
  },
  {
    title: "Buy crypto",
    url: "/buy-crypto",
  },
  {
    title: "Market",
    url: "/market",
  },
];

const company = [
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Careers",
    url: "/careers",
  },
  {
    title: "Developer Tools",
    url: "/developer-tools",
  },
];

const support = [
  {
    title: "Help Center",
    url: "/help-center",
  },
  {
    title: "Contact Us",
    url: "/contact-us",
  },
  {
    title: "Create Account",
    url: "/sign-in",
  },
  {
    title: "ID Verification",
    url: "/id-verification",
  },
  {
    title: "Account Information",
    url: "/account-information",
  },
];

const legal = [
  {
    title: "User Agreement",
    url: "/user-agreement",
  },
  {
    title: "AML/CFT",
    url: "/aml-cft",
  },
  {
    title: "Privacy",
    url: "/privacy",
  },
  {
    title: "Licenses",
    url: "/licenses",
  },
  {
    title: "",
    url: "",
  },
  {
    title: "Fees",
    url: "/fees",
  },
];

const socials = [
  // {
  //   title: "facebook",
  //   size: "16",
  //   url: "https://www.facebook.com/ui8.net/",
  // },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com",
  },
  // },
  // {
  //   title: "instagram",
  //   size: "16",
  //   url: "https://www.instagram.com/ui8net/",
  // },
  // {
  //   title: "dribbble",
  //   size: "16",
  //   url: "https://dribbble.com/ui8",
  // },
  // {
  //   title: "behance",
  //   size: "20",
  //   url: "https://www.behance.net/ui8",
  // },
];

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const [visibleCompany, setVisibleCompany] = useState(false);
  const [visibleSupport, setVisibleSupport] = useState(false);
  const [visibleLegal, setVisibleLegal] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    alert();
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <img
                className={styles.picDesktop}
                src="images/logo.png"
                alt="Byta"
              />
              <Image
                className={styles.picMobile}
                src="/images/logo-light.png"
                srcDark="/images/logo-dark.png"
                alt="Byta"
              />
            </Link>
            <div className={cn(styles.item, { [styles.active]: visible })}>
              <div
                className={styles.category}
                onClick={() => setVisible(!visible)}
              >
                footer nav
                <Icon name="arrow-down" size="24" />
              </div>
              <div className={styles.menu}>
                {menu.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>contact</div>
            <div className={styles.info}>
              <p>Global Wallet EOOD, UIC 207121280</p>
              <p>Sofia 1680</p>
              <p>Krasno selo District</p>
              <p>2V Tolpoli dol str, apt 16</p>
            </div>
          </div>
          <div className={styles.col}>
            <div
              className={cn(styles.itemLinks, {
                [styles.active]: visibleCompany,
              })}
            >
              <div
                className={styles.category}
                onClick={() => setVisibleCompany(!visibleCompany)}
              >
                Company
                <Icon name="arrow-down" size="24" />
              </div>
              <div className={styles.title}>Company</div>
              <div className={styles.menu}>
                {company.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div
              className={cn(styles.itemLinks, {
                [styles.active]: visibleSupport,
              })}
            >
              <div
                className={styles.category}
                onClick={() => setVisibleSupport(!visibleSupport)}
              >
                Support
                <Icon name="arrow-down" size="24" />
              </div>
              <div className={styles.title}>Support</div>
              <div className={styles.menu}>
                {support.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div
              className={cn(styles.itemLinks, {
                [styles.active]: visibleLegal,
              })}
            >
              <div
                className={styles.category}
                onClick={() => setVisibleLegal(!visibleLegal)}
              >
                Legal
                <Icon name="arrow-down" size="24" />
              </div>
              <div className={styles.title}>Legal</div>
              <div className={styles.menu}>
                {legal.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © 2021 Global Wallet EOOD. All rights reserved
          </div>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
