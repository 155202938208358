import { CopyToClipboard } from "react-copy-to-clipboard";
import cn from "classnames";

import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useToast } from "src/hooks/useToast";

import styles from "./Copy.module.sass";

type Props = {
  value: string;
  className?: string;
};

const Copy = ({ value, className }: Props) => {
  const { toastSuccess } = useToast();

  const handleCopy = () => {
    toastSuccess({ title: "Copied" });
  };

  return (
    <CopyToClipboard text={value} onCopy={handleCopy}>
      <ContentCopyOutlinedIcon
        fontSize="small"
        className={cn(styles.icon, className)}
      />
    </CopyToClipboard>
  );
};

export default Copy;
