import { useEffect, useState } from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import Dropdown from "../../../components/Dropdown";
import { currencySymbol } from "../../../utils";
import { useRematchDispatch } from "../../../hooks";
import {
  useMutationAssetPrice,
  useMutationFiatRate,
} from "../../../hooks/customHooks";

import styles from "./SelectCurrency.module.sass";

const priceVariants = ["50.00", "100.00", "200.00", "500.00"];

const SelectCurrency = ({ goNext }) => {
  const [currency, setCurrency] = useState("USD");
  const [asset, setAsset] = useState(null);
  const [amount, setAmount] = useState("50.00");
  const [currenciesList, setCurrenciesList] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [assetAmount, setAssetAmount] = useState(0);

  // const {
  //   fetchCurrencies,
  //   fetchAssets
  // } = useRematchDispatch((dispatch) => ({
  //   fetchCurrencies: dispatch.fiat.fetchCurrencies,
  //   fetchAssets: dispatch.fiat.fetchAssets,
  // }))
  const fiatDispatch = useRematchDispatch((dispatch) => dispatch.fiat);

  const currencies = useSelector((rootState) => rootState.fiat?.currencies);
  const assets = useSelector((rootState) => rootState.fiat?.assets);
  const priceMutation = useMutationAssetPrice();
  const rateMutation = useMutationFiatRate();

  useEffect(() => {
    const _currencies = currencies?.map((c) => c.currency);
    if (_currencies) {
      setCurrenciesList(_currencies || []);
      setCurrency(_currencies[0]);
      rateMutation.mutate({ currency: _currencies[0], amount: 1 });
    }
  }, [currencies]);

  useEffect(() => {
    const _assets = assets?.map((c) => c.asset_code);
    if (_assets) {
      setAssetsList(_assets || []);
      setAsset(_assets[0]);
      priceMutation.mutate(_assets[0]);
    }
  }, [assets]);

  useEffect(() => {
    fiatDispatch.fetchCurrencies();
    fiatDispatch.fetchAssets();
  }, []);

  useEffect(() => {
    if (
      rateMutation.status === "success" &&
      priceMutation.status === "success"
    ) {
      const _transactFee = rateMutation.data.fees.transaction_fee * amount;
      const _convertFee = rateMutation.data.fees.convert_fee * amount;
      const _amount =
        (amount - _transactFee - _convertFee) * rateMutation.data.rate;
      const _assetAmount = _amount / priceMutation.data.price;
      setAssetAmount(_assetAmount.toFixed(4));
    }
  }, [rateMutation.status, priceMutation.status, amount]);

  const handleAssetChange = (value) => {
    setAsset(value);
    priceMutation.mutate(value);
  };
  const handleCurrencyChange = (value) => {
    setCurrency(value);
    rateMutation.mutate({ currency: value, amount: 1 });
  };

  return (
    <form className={styles.item} action="">
      <div className={styles.title}>Select currency and payment method</div>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>Select currency</div>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={currency}
            setValue={handleCurrencyChange}
            options={currenciesList}
          />
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Select crypto</div>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={asset}
            setValue={handleAssetChange}
            options={assetsList}
          />
        </div>
      </div>
      <div className={styles.label}>Amount</div>
      <div className={styles.payment}>
        <div className={cn("h4", styles.sign)}>{currencySymbol(currency)}</div>
        <div className={styles.field}>
          <div className={styles.value}>{amount}</div>
          <input
            className={styles.input}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.price}>
        {parseInt(amount)} <span>{currencySymbol(currency)}</span>
      </div>
      <div className={styles.variants}>
        {priceVariants.map((x, index) => (
          <button
            className={cn("button-stroke button-small", styles.button)}
            type="button"
            onClick={() => setAmount(x.replace(currencySymbol(currency), ""))}
            key={index}
          >
            {`${x}${currencySymbol(currency)}`}
          </button>
        ))}
      </div>

      <div className={styles.label}>Receive</div>
      <div className={styles.payment}>
        <div className={cn("h4", styles.sign)}>{asset}</div>
        <div className={styles.field}>
          <div className={styles.value}>{assetAmount}</div>
          <input disabled className={styles.input} value={assetAmount} />
        </div>
      </div>

      <div className={styles.btns}>
        <button
          className={cn("button", styles.button)}
          onClick={() => goNext(amount, currency)}
        >
          Continue
        </button>
      </div>
    </form>
  );
};

export default SelectCurrency;
