import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";

import Image from "src/components/Image";
import Icon from "src/components/Icon";
import Theme from "src/components/Theme";
import { AuthProvider } from "src/components/core";

import styles from "./Header.module.sass";
import Dropdown from "./Dropdown";
import Settings from "./Settings";
import Notifications from "./Notifications";
import User from "./User";

const navigation = [
  {
    title: "Exchange",
    url: "/exchange/tBTCUSDT",
  },
  {
    title: "Buy Crypto",
    url: "/buy-crypto",
    // dropdown: [
    //   {
    //     title: "Credit card",
    //     icon: "user",
    //     url: "/buy-crypto",
    //   },
    //   // },
    //   // {
    //   //   title: "Bank deposit",
    //   //   icon: "image",
    //   //   url: "/deposit-fiat",
    //   // },
    // ],
  },
  {
    title: "Market",
    url: "/market",
  },
  // },
  // {
  //   title: "Discover",
  //   url: "/learn-crypto",
  // },
];

const Header = ({ headerWide }) => {
  const [visibleNav, setVisibleNav] = useState(false);
  return (
    <header className={cn(styles.header, { [styles.wide]: headerWide })}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logo}
          to="/"
          onClick={() => setVisibleNav(false)}
        >
          <Image
            className={styles.picDesktop}
            src="/images/logo-light.png"
            srcDark="/images/logo-dark.png"
            alt="Byta"
          />
          <img className={styles.picMobile} src="/images/logo.png" alt="Byta" />
        </Link>
        <div className={styles.wrapper}>
          <div className={cn(styles.wrap, { [styles.visible]: visibleNav })}>
            <nav className={styles.nav}>
              {navigation.map((x, index) =>
                x.dropdown ? (
                  <Dropdown
                    className={styles.dropdown}
                    key={index}
                    item={x}
                    setValue={setVisibleNav}
                  />
                ) : (
                  <NavLink
                    className={styles.item}
                    activeClassName={styles.active}
                    onClick={() => setVisibleNav(false)}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                )
              )}
            </nav>
            <AuthProvider>
              <NavLink
                className={cn("button-stroke", styles.button)}
                activeClassName={styles.active}
                to="/wallet-overview"
                onClick={() => setVisibleNav(false)}
              >
                Wallet
              </NavLink>
            </AuthProvider>
            <AuthProvider isCheckAuth={false}>
              <div className={styles.btns}>
                <Link
                  className={cn("button-small", styles.button)}
                  activeClassName={styles.active}
                  to="/sign-up"
                >
                  Sign Up
                </Link>
                <Link
                  className={cn("button-stroke button-small", styles.button)}
                  activeClassName={styles.active}
                  to="/sign-in"
                >
                  Login
                </Link>
              </div>
            </AuthProvider>
          </div>
          <Settings className={styles.settings} />
          <div className={styles.control}>
            <NavLink
              className={styles.activity}
              activeClassName={styles.active}
              to="/activity"
            >
              <Icon name="lightning" size="24" />
            </NavLink>
            {/* <Notifications className={styles.notifications} /> */}
            <AuthProvider>
              <NavLink
                className={cn("button-stroke button-small", styles.button)}
                activeClassName={styles.active}
                to="/wallet-overview"
              >
                Wallet
              </NavLink>
            </AuthProvider>
            <Theme className={styles.theme} icon />
            <AuthProvider>
              <User className={styles.user} />
            </AuthProvider>
          </div>
          <AuthProvider isCheckAuth={false}>
            <div className={styles.btns}>
              <Link
                className={cn("button-small", styles.button)}
                activeClassName={styles.active}
                to="/sign-up"
                onClick={() => setVisibleNav(false)}
              >
                Sign Up
              </Link>
              <Link
                className={cn("button-stroke button-small", styles.button)}
                activeClassName={styles.active}
                to="/sign-in"
                onClick={() => setVisibleNav(false)}
              >
                Login
              </Link>
            </div>
          </AuthProvider>
          <button
            className={cn(styles.burger, { [styles.active]: visibleNav })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </div>
    </header>
  );
};

export default Header;
