import { useState } from "react";

import Login from "src/components/Login";
import { Register } from "src/types/auth";

import Form from "./Form";
// import Confirm from "./Confirm";
import Code from "./Code";
import Success from "./Success";

const SignUp = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [formValues, setFormValues] = useState<Register>({});

  const handleNextForm = (values: Register) => {
    setActiveIndex(1);
    setFormValues(values);
  };

  return (
    <Login
      content="Already have an account?"
      linkText="Login"
      linkUrl="/sign-in"
    >
      {activeIndex === 0 && <Form goNext={handleNextForm} />}
      {/* {activeIndex === 1 && <Confirm goNext={() => setActiveIndex(2)} />} */}
      {activeIndex === 1 && (
        <Code formValues={formValues} onSuccess={() => setActiveIndex(2)} />
      )}
      {activeIndex === 2 && <Success />}
    </Login>
  );
};

export default SignUp;
