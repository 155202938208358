/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useForm, Controller } from "react-hook-form";

import { LoadingButton } from "@mui/lab";

import cn from "classnames";

import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";

import FormProvider from "../../../common/components/hook-form/FormProvider";

import styles from "./TwoFaEnabled.module.sass";

const TwoFaEnabled = ({ goDisabled, userInfo, getOTP }) => {
  const methods = useForm();
  const { handleSubmit, control } = methods;

  const handleGoDisabled = async (values) => {
    goDisabled(values);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleGoDisabled)}>
      <div>
        <div className={cn("h3", styles.title)}>
          2FA <span>Enabled</span>
        </div>
        <div className={styles.text}>
          If you want to turn off 2FA click "<span>Disable 2FA</span>".
        </div>
        <div className={styles.subtitle}>Disable 2FA</div>
        <div className={styles.email}>
          <Icon name="email" size="24" />
          {userInfo.email}
        </div>
        <div className={styles.field}>
          <Controller
            name="otp"
            control={control}
            render={({ field }) => (
              <TextInput
                className={styles.field}
                label="2FA code"
                name="code"
                type="text"
                required
                {...field}
              />
            )}
          />
        </div>
        <div className={styles.btns}>
          <LoadingButton
            sx={{
              borderRadius: 12,
              paddingTop: 1.5,
              paddingLeft: 3,
              paddingRight: 3,
              paddingBottom: 1.5,
              marginRight: 1.5,
              marginTop: 4,
              textTransform: "none",
              fontWeight: 600,
            }}
            variant="contained"
            onClick={getOTP}
          >
            Get code
          </LoadingButton>
          <button className={cn("button-red", styles.button)} type="submit">
            Disable 2FA
          </button>
        </div>
      </div>
    </FormProvider>
  );
};

export default TwoFaEnabled;
