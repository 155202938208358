import { useDispatch } from "react-redux";

import { RootDispatch } from "../stores";

export const useRematchDispatch = <D extends RootDispatch, MD>(
  selector: (dispatch: D) => MD
): MD => {
  const dispatch = useDispatch<D>();

  return selector(dispatch);
};
