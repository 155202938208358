import _ from "lodash";
import { useQuery } from "@tanstack/react-query";

import { FIAT_PATH } from "src/api/path";

import { getPrices } from "../api/fiat";

export const useGetPrice = () => {
  return useQuery({
    queryKey: [FIAT_PATH.PRICE_FEES],
    queryFn: () => getPrices(),
    keepPreviousData: true,
  });
};
