import { CircularProgress } from "@mui/material";

const LoadingButton = (props) => {
  const { classes, loading, buttonText, ...other } = props;

  if (loading)
    return (
      <button className={classes} {...other} disabled>
        <CircularProgress color="inherit" />
      </button>
    );
  else
    return (
      <button className={classes} {...other}>
        {buttonText}
      </button>
    );
};

export default LoadingButton;
