import React, { useState } from "react";
import cn from "classnames";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";

import Dropdown from "src/components/Dropdown";

import { useGetWalletData, useWithdrawRequest } from "src/hooks/useWallet";

import LoadingButton from "src/components/LoadingButton";

import Icon from "../Icon";
import TextInput from "../TextInput";

// import Checkbox from "../Checkbox";
import Successfully from "./Successfully";

import styles from "./Withdraw.module.sass";
import Confirm from "./Confirm";

const Withdraw = () => {
  // const [save, setSave] = useState(true);
  const [visibleWithdraw, setVisibleWithdraw] = useState(true);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false);
  const [registerId, setRegisterId] = useState("");
  const [tx, setTx] = useState("");

  const { control, setValue, reset, watch } = useForm({
    defaultValues: {
      amount: "",
      address: "",
      assetCode: "",
      network: "",
    },
  });

  const assetCode = watch("assetCode", "");
  const network = watch("network", "");
  const address = watch("address", "");
  const amount = watch("amount", "");

  const { assetCodes, available, networkCodes } = useGetWalletData({
    assetCode,
  });
  const { mutateAsync: withdrawRequest, isLoading: isLoadingWithdrawRequest } =
    useWithdrawRequest();

  const handleTogleMaxAmount = () => {
    setValue("amount", available);
  };

  const handleChangeCoin = (value) => {
    reset({ assetCode: value });
  };

  const handleSubmitForm = async () => {
    const { data } = await withdrawRequest({
      asset_code: assetCode,
      address,
      amount,
      network,
    });
    const register_id = _.get(data, "message.register_id");
    setRegisterId(register_id);
    setVisibleWithdraw(false);
    setVisibleConfirm(true);
  };

  const handleSuccessConfirm = (tx) => {
    setTx(tx);
    setVisibleConfirm(false);
    setVisibleSuccessfully(true);
  };

  const isDisableSubmit = !assetCode || !network || !address || !amount;

  return (
    <>
      {visibleWithdraw && (
        <div className={styles.withdraw}>
          <div className={cn("h4", styles.title)}>
            <Icon name="arrow-left" size="32" />
            Withdraw
          </div>
          <div className={styles.field}>
            <Dropdown
              label="coin"
              value={assetCode}
              setValue={handleChangeCoin}
              options={assetCodes}
            />
          </div>
          <div className={styles.field}>
            <Dropdown
              label="network"
              value={network}
              setValue={(value) => setValue("network", value)}
              options={networkCodes}
            />
          </div>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <TextInput
                className={styles.field}
                label="address"
                name="address"
                type="text"
                // note={`Do not send Tether USD unless you are certain the destination supports
                //   TRC-20 transactions. If it does not, you could permanently lose access
                //   to your coins.`}
                required
                {...field}
              />
            )}
          />

          {/* <Checkbox
            className={styles.checkbox}
            value={save}
            onChange={() => setSave(!save)}
            content="Save address for next time"
          /> */}
          {assetCode && (
            <div className={styles.wrap}>
              <div className={styles.category}>
                Available <br />
                {/* balance */}
              </div>
              <div className={styles.details}>
                <div className={styles.currency}>
                  {available} {assetCode}
                </div>
                {/* <div className={styles.price}>${balance}</div> */}
              </div>
            </div>
          )}
          <div className={styles.box}>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <TextInput
                  className={styles.field}
                  label="Amount to withdraw"
                  name="amount"
                  type="number"
                  // note="$1,000,000.00 daily withdrawal limit remaining."
                  required
                  {...field}
                />
              )}
            />
            <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={handleTogleMaxAmount}
            >
              Max amount
            </button>
          </div>
          {/* <TextInput
            className={styles.field}
            label="transaction fee"
            name="transaction"
            type="text"
            placeholder="0.00000000 USDT"
            required
          />
          <TextInput
            className={styles.field}
            label="total"
            name="total"
            type="text"
            placeholder="0.00000000 USDT"
            required
          /> */}
          <LoadingButton
            className={cn("button", styles.button)}
            onClick={handleSubmitForm}
            buttonText="Withdraw"
            loading={isLoadingWithdrawRequest}
            disabled={isDisableSubmit}
          />
        </div>
      )}
      {visibleConfirm && (
        <Confirm registerId={registerId} onSuccess={handleSuccessConfirm} />
      )}
      {visibleSuccessfully && (
        <Successfully
          address={address}
          assetCode={assetCode}
          amount={amount}
          tx={tx}
        />
      )}
    </>
  );
};

export default Withdraw;
