import { useState } from "react";
import cn from "classnames";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// import Icon from "src/components/Icon";
import Checkbox from "src/components/Checkbox";
import TextInput from "src/components/TextInput";
import PasswordInput from "src/components/Password";
import { Register } from "src/types/auth";
import {
  CONFIRM_PASSWORD_IS_REQUIRED,
  EMAIL_IS_REQUIRED,
  INVALID_EMAIL,
  PASSWORDS_DO_NOT_MATCH,
  PASSWORD_IS_REQUIRED,
  USERNAME_IS_REQUIRED,
} from "src/constants/message";

import styles from "./Form.module.sass";

const formSchema = Yup.object().shape({
  username: Yup.string().required(USERNAME_IS_REQUIRED),
  email: Yup.string().email(INVALID_EMAIL).required(EMAIL_IS_REQUIRED),
  password: Yup.string().required(PASSWORD_IS_REQUIRED),
  confirmPassword: Yup.string()
    .required(CONFIRM_PASSWORD_IS_REQUIRED)
    .test("passwords-match", PASSWORDS_DO_NOT_MATCH, function (value) {
      return this.parent.password === value;
    }),
});

type Props = {
  goNext?: (values: Register) => void;
};

const Form = ({ goNext }: Props) => {
  const [policy, setPolicy] = useState(false);

  const {
    register,
    formState: { errors, touchedFields },
    handleSubmit,
  } = useForm<Register>({
    resolver: yupResolver(formSchema),
  });

  const handleSubmitForm = async (values: Register) => {
    policy && goNext && goNext(values);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(handleSubmitForm)}>
      <div className={styles.top}>
        <h3 className={cn("h3", styles.title)}>Sign up</h3>
        {/* <div className={styles.info}>Use Your OpenID to Sign up</div> */}
        {/* <div className={styles.btns}>
          <button className={cn("button", styles.button)}>
            <Icon name="google" size="16" />
            <span>Google</span>
          </button>
          <button className={cn("button-black", styles.button)}>
            <Icon name="apple" size="16" />
            <span>Apple</span>
          </button>
        </div> */}
      </div>
      {/* <div className={styles.info}>Or continue with email</div> */}
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="User name"
          type="text"
          placeholder="User name"
          error={!!(errors.username && touchedFields.username)}
          message={errors.username?.message}
          required
          {...register("username")}
        />
        <TextInput
          className={styles.field}
          label="email"
          type="email"
          placeholder="Email address"
          error={!!(errors.email && touchedFields.email)}
          message={errors.email?.message}
          required
          {...register("email")}
        />
        <PasswordInput
          className={styles.field}
          label="Password"
          placeholder="Password"
          error={!!(errors.password && touchedFields.password)}
          message={errors.password?.message}
          required
          {...register("password")}
        />
        <PasswordInput
          className={styles.field}
          label="confirm password"
          placeholder="Password"
          error={!!(errors.confirmPassword && touchedFields.confirmPassword)}
          message={errors.confirmPassword?.message}
          required
          {...register("confirmPassword")}
        />
        <Checkbox
          className={styles.checkbox}
          value={policy}
          onChange={() => setPolicy(!policy)}
          content="<span>By signing up I agree that I’m 18 years of age or older, to the <a href='/#' target='_blank' rel='noopener noreferrer'>User Agreements</a>, <a href='/#' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>, <a href='/#' target='_blank' rel='noopener noreferrer'>Cookie Policy</a>, <a href='/#' target='_blank' rel='noopener noreferrer'>E-Sign Consent</a>.<span>"
        />
      </div>
      <button
        className={cn("button", styles.button)}
        type="submit"
        disabled={!policy}
      >
        Sign up
      </button>
    </form>
  );
};

export default Form;
