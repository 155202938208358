import React, { useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import LoadingButton from "src/components/LoadingButton";
import { useToast } from "src/hooks/useToast";
import Icon from "src/components/Icon";
import Modal from "src/components/Modal";
import { currencySymbol } from "src/utils";
import { useRematchDispatch } from "src/hooks";

import Successfully from "./Successfully";
import styles from "./ConfirmOrder.module.sass";

const ConfirmOrder = ({ goBack, goStart }) => {
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { toastError } = useToast();

  const { asset, amount, assetAmount, currency, paymentOptionName, fees } =
    useSelector((rootState) => rootState.fiat);

  const options = [
    {
      title: "Spend",
      content: `${currencySymbol(currency)}${amount}`,
      color: "#9757D7",
      icon: "wallet",
    },
    {
      title: "Get",
      content: `${assetAmount} ${asset}`,
      color: "#58BD7D",
      icon: "wallet",
    },
    {
      title: "Method",
      content: `${paymentOptionName}`,
      color: "#4BC9F0",
      icon: "wallet",
    },
  ];

  const parameters = [
    {
      title: assetAmount,
      content: asset,
    },
    {
      title: "Transaction fee",
      content: `${currencySymbol(currency)}${fees?.transaction_fee}`,
    },
    {
      title: "Currency convert fee",
      content: `${currencySymbol(currency)}${fees?.convert_fee}`,
    },
    {
      title: "You will pay",
      content: `${currencySymbol(currency)}${amount}`,
    },
  ];

  const fiatDispatch = useRematchDispatch((dispatch) => dispatch.fiat);
  const handlePaymentSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await fiatDispatch.submitPayment();
      if (response) {
        setVisibleSuccessfully(true);
      }
    } catch (error) {
      toastError({
        title: "Error",
        description: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styles.item}>
        <div className={styles.control}>
          <button className={styles.back} onClick={goBack}>
            <Icon name="arrow-prev" size="14" />
            Confirm order
          </button>
          <div className={styles.money}>
            Buying Bitcoin
            <img src="/images/content/currency/bitcoin.svg" alt="Coin" />
          </div>
        </div>
        <div className={styles.options}>
          {options.map((x, index) => (
            <div className={styles.option} key={index}>
              <div className={styles.icon} style={{ backgroundColor: x.color }}>
                <Icon name={x.icon} size="20" />
              </div>
              <div className={styles.details}>
                <div className={styles.category}>{x.title}</div>
                <div className={styles.content}>{x.content}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.info}>
          You are about to buy {assetAmount} {asset} from Byta
        </div>
        <div className={styles.table}>
          {parameters.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.cell}>{x.title}</div>
              <div className={styles.cell}>{x.content}</div>
            </div>
          ))}
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-stroke", styles.button)}
            onClick={goStart}
          >
            Cancel
          </button>
          <LoadingButton
            className={cn("button", styles.button)}
            onClick={handlePaymentSubmit}
            buttonText="I understand, continue"
            loading={isLoading}
            disabled={false}
          />
        </div>
      </div>
      <Modal
        visible={visibleSuccessfully}
        onClose={() => setVisibleSuccessfully(false)}
      >
        <Successfully />
      </Modal>
    </>
  );
};

export default ConfirmOrder;
