import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import styles from "./Login.module.sass";

type Props = {
  className?: string;
  content?: string;
  linkText?: string;
  linkUrl?: string;
  children?: React.ReactNode;
};

const Login = ({
  className,
  content,
  linkText,
  linkUrl = "",
  children,
}: Props) => {
  return (
    <div className={cn(className, styles.login)}>
      <div
        className={styles.col}
        style={{
          backgroundImage: "url(/images/content/bg-login.png)",
        }}
      >
        <Link className={styles.logo} to="/">
          <img src="/images/logo-light.png" alt="Byta" />
          <img src="/images/logo-dark.png" alt="Byta" />
        </Link>
      </div>
      <div className={styles.col}>
        <div className={styles.head}>
          <span>{content}</span>
          <Link className={styles.link} to={linkUrl}>
            {linkText}
          </Link>
        </div>
        <div className={styles.wrap}>{children}</div>
      </div>
    </div>
  );
};

export default Login;
