import { Box, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

import TextInput from "src/components/TextInput";
import Dropdown from "src/components/Dropdown";

import {
  FormProvider,
  RHFTextField,
  RHFSelect,
} from "../../../common/components/hook-form";
import { RootDispatch, RootState } from "../../../stores";
import { useRematchDispatch } from "../../../hooks";

import styles from "./ProfileLV1.module.sass";

type FormValuesProps = {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  country: string | null;
  timezone: string | null;
};

const UpdateProfileLv1 = ({ defaultValues }: { defaultValues: any }) => {
  const countries: any = useSelector(
    (rootState: RootState) => rootState.common?.countries
  );
  const [countriesTransformed, setCountryTransformed] = useState([]);
  const timezones: any = useSelector(
    (rootState: RootState) => rootState.common?.timezones
  );
  const [timezonesTransformed, setTimezonesTransformed] = useState([]);
  const { updateUserLv1 } = useRematchDispatch((dispatch: RootDispatch) => ({
    updateUserLv1: dispatch.user.updateUserLv1,
  }));

  const methods = useForm<FormValuesProps>({
    ...{
      defaultValues,
    },
  });

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const countryDefaultValue = defaultValues.country
    ? defaultValues.country
    : "";
  const country = watch("country", countryDefaultValue);
  const timezoneDefaultValue = defaultValues.timezone
    ? defaultValues.timezone
    : "";
  const timezone = watch("timezone", timezoneDefaultValue);

  // @ts-ignore
  const handleChangeCountry = (value) => {
    reset((formValues) => ({
      ...formValues,
      country: value,
    }));
  };

  const transformCountries = () => {
    // @ts-ignore
    const countriesNameArr = [];
    Object.keys(countries).forEach((k) => {
      countriesNameArr.push(countries[k].country_name);
      if (countries[k].id === country) {
        handleChangeCountry(countries[k].country_name);
      }
    });

    // @ts-ignore
    setCountryTransformed(countriesNameArr);
  };

  const getSelectedCountryId = (countryName: any) => {
    let countryId = -1;
    Object.keys(countries).forEach((k) => {
      const currentCountryName = countries[k].country_name;
      if (currentCountryName === countryName) {
        countryId = countries[k].id;
      }
    });
    return countryId;
  };

  // @ts-ignore
  const handleChangeTimezone = (value) => {
    //reset({ timezone: value });
    reset((formValues) => ({
      ...formValues,
      timezone: value,
    }));
  };

  const transformTimezones = () => {
    // @ts-ignore
    const timezonesNameArr = [];
    Object.keys(timezones).forEach((k) => {
      timezonesNameArr.push(timezones[k].timezone);
      if (timezones[k].id === timezone) {
        handleChangeTimezone(timezones[k].timezone);
      }
    });

    // @ts-ignore
    setTimezonesTransformed(timezonesNameArr);
  };

  const getSelectedTimezoneId = (timezoneName: any) => {
    let timezoneId = -1;
    Object.keys(timezones).forEach((k) => {
      const currentTimezoneName = timezones[k].timezone;
      if (currentTimezoneName === timezoneName) {
        timezoneId = timezones[k].id;
      }
    });
    return timezoneId;
  };

  const dataFormated = (data: any, country: any, timezone: any) => {
    const countryId = getSelectedCountryId(country);
    const timezoneId = getSelectedTimezoneId(timezone);
    return {
      countryid: countryId,
      timezoneid: timezoneId,
      firstname: data.firstName,
      lastname: data.lastName,
      middlename: data.middleName,
    };
  };

  const onSubmit = async (data: any) => {
    try {
      const formData = dataFormated(data, country, timezone);
      await updateUserLv1(formData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (defaultValues.country === -1) {
      defaultValues.country = "";
    }
    if (defaultValues.timezone === -1) {
      defaultValues.timezone = "";
    }
    methods.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    transformCountries();
  }, [countries]);

  useEffect(() => {
    transformTimezones();
  }, [timezones]);

  return (
    <FormProvider
      className={[]}
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          display: "grid",
          rowGap: 3,
          columnGap: 2,
          gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)" },
        }}
      >
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <TextInput
              className={styles.field}
              label="First Name"
              type="text"
              {...field}
            />
          )}
        />
        <Controller
          name="middleName"
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <TextInput
              className={styles.field}
              label="Middle Name"
              type="text"
              {...field}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <TextInput
              className={styles.field}
              label="Last Name"
              type="text"
              {...field}
            />
          )}
        />
        <div className={styles.field}>
          {
            // @ts-ignore
            <Dropdown
              label="Country"
              value={country}
              setValue={handleChangeCountry}
              options={countriesTransformed}
            />
          }
        </div>
        <div className={styles.field}>
          {
            // @ts-ignore
            <Dropdown
              label="Timezone"
              value={timezone}
              setValue={handleChangeTimezone}
              options={timezonesTransformed}
            />
          }
        </div>
      </Box>

      <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
        <LoadingButton
          sx={{
            borderRadius: 12,
            paddingTop: 1.5,
            paddingLeft: 3,
            paddingRight: 3,
            paddingBottom: 1.5,
            textTransform: "none",
            fontWeight: 600,
          }}
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Save Changes
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export default UpdateProfileLv1;
