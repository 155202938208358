import { createModel } from "@rematch/core";

import apiClient from "../../middlewares/api-client";

import { RootModel } from ".";

export interface AssetState {
  // permanentInfo: any[]
  assets: any;
}

const initState = {
  // permanentInfo: [],
  assets: undefined,
} as AssetState;

export const asset = createModel<RootModel>()({
  state: initState,
  reducers: {
    setAssets(state, assets) {
      state.assets = assets;
    },

    reset() {
      return initState;
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  effects: () => ({
    async fetchAssets() {
      const response = await apiClient.post("currency/assets/all/");
      const { message: assets } = response.data || [];
      this.setAssets(assets);
      return true;
    },
  }),
});
