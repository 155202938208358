export class MessageError {
  private readonly message: string;
  constructor(message: string) {
    this.message = message;
  }
  get messageError() {
    return this.message;
  }
}

export interface Country {
  id: number;
  country_code: string;
  country_name: string;
}
