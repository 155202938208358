import Legal from "../../components/Legal";

import Content from "./Content";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "User Agreement",
  },
];

const UserAgreement = () => {
  return (
    <Legal title="User Agreement" breadcrumbs={breadcrumbs}>
      <Content />
    </Legal>
  );
};

export default UserAgreement;
