import cn from "classnames";
import { useState } from "react";
import ReactCodeInput from "react-code-input";
import LoadingButton from "src/components/LoadingButton";
import { useRegisterAccount } from "src/hooks/useAuth";

import { Register } from "src/types/auth";

import styles from "./Code.module.sass";

type Props = {
  formValues: Register;
  onSuccess: () => void;
};

const Code = ({ formValues, onSuccess }: Props) => {
  const [code, setCode] = useState("");

  const { mutateAsync, isLoading } = useRegisterAccount();

  const handleChange = (value: string) => {
    setCode(value);
  };

  const handleSumitForm = async () => {
    await mutateAsync({ ...formValues, pincode: code });
    onSuccess();
  };

  return (
    <form className={styles.form}>
      <h3 className={cn("h3", styles.title)}>Enter your security code</h3>
      {/* <div className={styles.info}>We texted your code to +1 234 567 890</div> */}
      <ReactCodeInput
        name="code"
        inputMode="numeric"
        className={styles.code}
        inputStyle={{
          borderRadius: "4px",
          height: "96px",
          textAlign: "center",
          border: "1px solid #ccc",
          flex: "0 0 calc(25% - 16px)",
          width: "calc(25% - 16px)",
          margin: "0 8px",
          fontSize: "32px",
          fontWeight: 600,
        }}
        onChange={handleChange}
      />
      {/* <div className={styles.code}>
        <div className={styles.number}>
          <input type="tel" maxLength={1} />
        </div>
        <div className={styles.number}>
          <input type="tel" maxLength={1} />
        </div>
        <div className={styles.number}>
          <input type="tel" maxLength={1} />
        </div>
        <div className={styles.number}>
          <input type="tel" maxLength={1} />
        </div>
      </div> */}
      <LoadingButton
        className={cn("button", styles.button)}
        sx={{
          borderRadius: 12,
          padding: 1.5,
          marginTop: 4,
          textTransform: "none",
          fontWeight: 600,
        }}
        type="button"
        variant="contained"
        buttonText="Submit"
        loading={isLoading}
        disabled={code.length !== 4}
        onClick={handleSumitForm}
      />
    </form>
  );
};

export default Code;
