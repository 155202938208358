import { fetchData } from "src/hooks/customHooks";
import { CreateOrder } from "src/types/exchange";

import apiClient from "../middlewares/api-client";

import { EXCHANGE_PATH } from "./path";

type History = {
  base_currency_code: string;
  base_currency_id: string;
  commission: number;
  create_date: string;
  id: string;
  order_id: string;
  price: string;
  quantity: string;
  quote_currency_code: string;
  quote_currency_id: string;
  side: string;
  symbol: string;
  user_id: string;
  value: number;
};

type OpenOrder = History;

type Pagination = {
  items: number;
  pages: number;
  page: number;
  perpage: number;
};

type Response<T = any> = {
  items: T[];
  pagination: Pagination;
};

export const createOrder = (payload: CreateOrder) => {
  return apiClient.post(
    EXCHANGE_PATH.CREATE_ORDER,
    sanitizeCreateOrder(payload)
  );
};

export const cancelOrder = (order_id: string) => {
  return apiClient.post(EXCHANGE_PATH.CANCEL_ORDER, { order_id });
};

export const getHistories = (
  symbol: string,
  marketid: string
): Promise<Response<History>> => {
  return fetchData(EXCHANGE_PATH.GET_HISTORIES, { symbol, marketid });
};

export const getOpenOrders = (
  symbol: string,
  marketid: string
): Promise<Response<OpenOrder>> => {
  return fetchData(`${EXCHANGE_PATH.GET_OPEN_ORDER}${symbol}/both/`, {
    marketid,
  });
};

export const getOrderBooks = (marketid: string) => {
  return fetchData(`${EXCHANGE_PATH.GET_ORDER_BOOK}?limit=50`, { marketid });
};

const sanitizeCreateOrder = ({
  price,
  quantity,
  symbol,
  side,
  type,
}: CreateOrder) => {
  return {
    price,
    quantity,
    symbol,
    side,
    type,
  };
};
