import React from "react";
import cn from "classnames";

import styles from "../OrderBook.module.sass";
import Icon from "../../../../components/Icon";
import { formatNumber } from "../../../../utils";
import OrderRow from "../OrderRow";

const OrderBookOrdering = ({
  sells,
  buys,
  price,
  totalBuy,
  totalSell,
  orderingType,
}) => {
  if (orderingType === 0) {
    return (
      <>
        {sells?.map((x, index) => (
          <OrderRow order={{ ...x, negative: true }} key={`0sell_${index}`} />
        ))}

        <div className={cn(styles.statistics, styles.positive)} key="sum_0">
          <div className={styles.currency}>{formatNumber(price)}</div>
          <Icon name="arrow-top" size="16" />
          <div className={styles.money}>{formatNumber(totalBuy)}</div>
        </div>

        {/* Generate buys */}
        {buys?.map((x, index) => (
          <OrderRow order={{ ...x, positive: true }} key={`0buy_${index}`} />
        ))}
      </>
    );
  }
  if (orderingType === 1) {
    return (
      <>
        <div className={cn(styles.statistics, styles.positive)} key="sum_1">
          <div className={styles.currency}>{formatNumber(price)}</div>
          <Icon name="arrow-top" size="16" />
          <div className={styles.money}>{formatNumber(totalBuy)}</div>
        </div>

        {/* Generate buys */}
        {buys?.map((x, index) => (
          <OrderRow order={{ ...x, positive: true }} key={`1buy_${index}`} />
        ))}
      </>
    );
  }
  if (orderingType === 2) {
    return (
      <>
        <div className={cn(styles.statistics, styles.negative)} key="sum_2">
          <div className={styles.currency}>{formatNumber(price)}</div>
          <Icon name="arrow-bottom" size="16" />
          <div className={styles.money}>{formatNumber(totalSell)}</div>
        </div>

        {/* Generate sells */}
        {sells?.map((x, index) => (
          <OrderRow order={{ ...x, negative: true }} key={`2sell_${index}`} />
        ))}
      </>
    );
  }
  return <></>;
};

export default OrderBookOrdering;
