import cn from "classnames";
import React, { useState } from "react";

import Icon from "src/components/Icon";

import styles from "./Password.module.sass";

type PasswordInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  className?: string;
  classLabel?: string;
  classInput?: string;
  label?: string;
  empty?: boolean;
  view?: boolean;
  note?: string;
  error?: boolean;
  message?: string;
};

const Password = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    {
      className,
      classLabel,
      classInput,
      label,
      empty,
      view,
      note,
      error,
      message,
      ...props
    },
    ref
  ) => {
    const [isShow, setIsShow] = useState(false);

    const handleShow = () => {
      setIsShow((current) => !current);
    };

    return (
      <div
        className={cn(
          styles.field,
          { [styles.empty]: empty },
          { [styles.view]: view },
          className
        )}
      >
        {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
        <div className={styles.wrap}>
          <input
            className={cn(classInput, styles.input)}
            type={isShow ? "text" : "password"}
            ref={ref}
            {...props}
          />
          <button className={styles.toggle} type="button" onClick={handleShow}>
            <Icon name="eye" size="24" />
          </button>
          {note && <div className={styles.note}>{note}</div>}
          {error && <div className={styles.message}>{message}</div>}
        </div>
      </div>
    );
  }
);

export default Password;
