import { useMutation } from "@tanstack/react-query";
import { registerAccount } from "src/api/auth";

import { useHandleError } from "./useToast";

export const useRegisterAccount = () => {
  const { handleError } = useHandleError();

  return useMutation(registerAccount, {
    onError: (errors) => handleError(errors),
  });
};
