import apiClient from "src/middlewares/cms-api-client";

import { CMS_PATH } from "./path";

export const policyRequest = () => {
  return apiClient.get(CMS_PATH.POLICY);
};

export const aboutRequest = () => {
  return apiClient.get(CMS_PATH.ABOUT);
};

export const careersRequest = () => {
  return apiClient.get(CMS_PATH.CAREERS);
};

export const developerToolsRequest = () => {
  return apiClient.get(CMS_PATH.DEVELOPER_TOOLS);
};

export const helpCenterRequest = () => {
  return apiClient.get(CMS_PATH.HELP_CENTER);
};

export const contactUsRequest = () => {
  return apiClient.get(CMS_PATH.CONTACT_US);
};

export const idVerificationRequest = () => {
  return apiClient.get(CMS_PATH.ID_VERIFICATION);
};

export const accountInformationRequest = () => {
  return apiClient.get(CMS_PATH.ACCOUNT_INFORMATION);
};

export const userAgreementRequest = () => {
  return apiClient.get(CMS_PATH.USER_AGREEMENT);
};

export const amlCftRequest = () => {
  return apiClient.get(CMS_PATH.AML_CFT);
};

export const licensesRequest = () => {
  return apiClient.get(CMS_PATH.LICENSES);
};

export const feesRequest = () => {
  return apiClient.get(CMS_PATH.FEES);
};
