import _ from "lodash";
import { useMutation, useQuery } from "@tanstack/react-query";

import { WALLET_PATH } from "src/api/path";

import { getTransactions } from "../api/wallet";
import {
  getDepositAddress,
  withdrawConfirm,
  withdrawRequest,
} from "../api/wallet";

import { useGetStore } from "./useStore";
import { useHandleError } from "./useToast";
import { useRematchDispatch } from "./useRematchDispatch";

type UseGetHistoryProps = {
  limit: number;
  page: number;
  type: string;
};

export const useGetHistories = ({ limit, page, type }: UseGetHistoryProps) => {
  return useQuery({
    queryKey: [WALLET_PATH.TRANSACTIONS, limit, page, type],
    queryFn: () => getTransactions(limit, page, type),
    keepPreviousData: true,
  });
};

type UseGetWalletDataProps = {
  assetCode: string;
};

export const useGetWalletData = ({ assetCode }: UseGetWalletDataProps) => {
  const balance = useGetStore("balance");
  const balances = _.get(balance, "balances");

  const getAssetCodes = () => {
    const assetCodes: string[] = [];
    _.forOwn(balances, (item) => {
      assetCodes.push(_.get(item, "asset_code"));
    });
    return assetCodes;
  };

  const getBalanceSelected = () => {
    const balanceSelected =
      _.findKey(balances, ["asset_code", assetCode]) || "";
    return _.get(balances, balanceSelected);
  };

  const getAvailable = () => {
    const coinSelected = getBalanceSelected();
    const data = Number(_.get(coinSelected, "available"));

    return _.isNaN(data) ? 0 : data.toFixed(8);
  };

  return {
    assetCodes: getAssetCodes(),
    available: getAvailable(),
    networkCodes: ["ETH", "POLYGON", "TRON"],
  };
};

export const useFetchWalletData = () => {
  const { fetchBalance } = useRematchDispatch((dispatch: any) => ({
    fetchBalance: dispatch.balance.fetchBalance,
  }));
  return {
    fetchBalance,
  };
};

export const useGetDepositAddress = () => {
  const { handleError } = useHandleError();

  return useMutation(getDepositAddress, {
    onError: (error) => handleError(error),
  });
};

export const useWithdrawRequest = () => {
  const { handleError } = useHandleError();

  return useMutation(withdrawRequest, {
    onError: (error) => handleError(error),
  });
};

export const useWithdrawConfirm = () => {
  const { fetchBalance } = useFetchWalletData();
  const { handleError } = useHandleError();

  return useMutation(withdrawConfirm, {
    onSuccess: () => {
      fetchBalance();
    },
    onError: (error) => handleError(error),
  });
};
