import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import _ from "lodash";

import {
  useGetMarkets,
  useGetSimpleQuotes,
  cal24hRate,
} from "src/hooks/useMarket";
import Dropdown from "src/components/Dropdown";
import { transformQuotes } from "src/utils";
import { formatNumber } from "src/utils";

import styles from "./Panel.module.sass";

const navigation = [
  "Cryptocurrencies",
  // "DeFi",
  // "Innovation",
  // "POS",
  // "NFT",
  // "POW",
];
const Panel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(navigation[0]);
  const { hotMarkets } = useGetMarkets();
  const marketIds = hotMarkets?.slice(0, 3).map((k) => k.id);
  const quotesQueries = useGetSimpleQuotes({ marketIds });
  // TODO: will be replaced
  const imgBasePath = "/images/content/currency/";

  return (
    <div className={styles.panel}>
      <div className={styles.body}>
        <div className={styles.list}>
          {hotMarkets?.slice(0, 3).map((x, index) => (
            <Link className={styles.item} key={index} to={x.url}>
              <div className={styles.icon}>
                <img
                  src={imgBasePath + x.base_currency_code + ".svg"}
                  alt="Currency"
                />
              </div>
              <div className={styles.details}>
                <div className={styles.line}>
                  <div className={styles.title}>
                    {x.base_currency_code}/{x.quote_currency_code}
                  </div>
                  {x.change_24 >= 0 && (
                    <div className={styles.positive}>
                      +{cal24hRate(x.last_price, x.change_24)}%
                    </div>
                  )}
                  {x.change_24 < 0 && (
                    <div className={styles.negative}>
                      {cal24hRate(x.last_price, x.change_24)}%
                    </div>
                  )}
                </div>
                <div className={styles.price}>
                  {formatNumber(x.last_price, 8)}
                </div>
                <div className={styles.currency}>
                  {formatNumber(x.last_price, 8)}
                </div>
              </div>
              <div className={styles.chart}>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    width={500}
                    height={400}
                    data={transformQuotes(
                      _.get(quotesQueries, `[${index}].data`, [])
                    )}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <defs>
                      <linearGradient
                        id="colorPrice"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor="#45B36B"
                          stopOpacity={0.6}
                        />
                        <stop
                          offset="95%"
                          stopColor="#45B36B"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <Area
                      type="monotone"
                      dataKey="price"
                      stroke="#58BD7D"
                      fillOpacity={1}
                      fill="url(#colorPrice)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className={styles.foot}>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        <Link
          className={cn("button-stroke button-small", styles.button)}
          to="/exchange"
        >
          Trade
        </Link>
        <Dropdown
          className={styles.dropdown}
          value={sorting}
          setValue={setSorting}
          options={navigation}
        />
      </div>
    </div>
  );
};

export default Panel;
