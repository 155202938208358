import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import cn from "classnames";

import TextInput from "../../../components/TextInput";
import { FormProvider } from "../../../common/components/hook-form";
import { useRematchDispatch } from "../../../hooks";

import styles from "./Form.module.sass";
import "react-toastify/dist/ReactToastify.css";

const Form = ({ goFinish }) => {
  const { newPassword } = useRematchDispatch((dispatch) => ({
    newPassword: dispatch.user.resetPassword,
  }));

  const methods = useForm();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const handleNewPassword = async (values) => {
    if (values.newPassword !== values.confirmPassword) {
      toast.warn("The confirmation password doesn't match the new password");
      return;
    }

    const result = await newPassword(values);
    if (result) {
      goFinish();
    } else {
      toast.error("Failed to change password");
    }
  };

  return (
    <FormProvider
      className={styles.form}
      methods={methods}
      onSubmit={handleSubmit(handleNewPassword)}
    >
      <div className={cn("h3", styles.title)}>New password</div>
      <Controller
        name="oldPassword"
        control={control}
        render={({ field }) => (
          <TextInput
            className={styles.field}
            label="old password"
            name="old-password"
            type="password"
            placeholder="Old Password"
            required
            {...field}
          />
        )}
      />
      <Controller
        name="newPassword"
        control={control}
        render={({ field }) => (
          <TextInput
            className={styles.field}
            label="new password"
            name="new-password"
            type="password"
            placeholder="Password"
            required
            view
            {...field}
          />
        )}
      />
      <Controller
        name="confirmPassword"
        control={control}
        render={({ field }) => (
          <TextInput
            className={styles.field}
            label="confirm password"
            name="confirm-password"
            type="password"
            placeholder="Password"
            required
            view
            {...field}
          />
        )}
      />
      <LoadingButton
        className={cn("button", styles.button)}
        sx={{
          borderRadius: 12,
          padding: 1.5,
          textTransform: "none",
          fontWeight: 600,
        }}
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Change Password
      </LoadingButton>
    </FormProvider>
  );
};

export default Form;
