import React, { useState } from "react";
import cn from "classnames";

import { useGetDataExchange, useGetHistories } from "src/hooks/useExchange";
import { formatNumber, getTime } from "src/utils";

import styles from "./HistoryTrades.module.sass";

const navigation = ["My trades"];

const HistoryTrades = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { symbol, marketId, baseCurrencyCode, quoteCurrencyCode } =
    useGetDataExchange();
  const { data } = useGetHistories({ symbol, marketid: marketId });

  return (
    <div className={styles.trades}>
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Price ({quoteCurrencyCode})</div>
          <div className={styles.col}>Amount ({baseCurrencyCode})</div>
          <div className={styles.col}>Time</div>
        </div>
        {data?.items?.map((x, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.col}>
              {x.side === "BUY" && (
                <div className={styles.positive}>{formatNumber(x.price)}</div>
              )}
              {x.side === "SELL" && (
                <div className={styles.negative}>{formatNumber(x.price)}</div>
              )}
            </div>
            <div className={styles.col}>{formatNumber(x.quantity)}</div>
            <div className={styles.col}>{getTime(x.create_date)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HistoryTrades;
