import moment from "moment";
import Payment from "payment";
import _ from "lodash";

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const formatCurrency = (amount, currency = "USD") => {
  const locale = "en-US";
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });

  const response = formatter.format(amount);
  return response;
};

export const currencySymbol = (currency = "USD") => {
  const locale = "en-US";
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });

  let symbol;
  formatter.formatToParts(0).forEach(({ type, value }) => {
    if (type === "currency") {
      symbol = value;
    }
  });

  return symbol;
};

export const formatNumber = (value, decimal = 3) => {
  if (typeof value === "number") return value.toFixed(decimal);
  else {
    const fVal = parseFloat(value);
    return fVal.toFixed(decimal);
  }
};

export const isBigNumber = (value) => {
  formatNumber(value);
  const numberofIntegerDigits =
    (Math.log10((value ^ (value >> 31)) - (value >> 31)) | 0) + 1;
  return numberofIntegerDigits > 8;
};

export const getTime = (datetimeVal) => {
  const time = moment(datetimeVal).format("hh:mm:ss");
  return time;
};

export const getDate = (datetimeVal) => {
  const time = moment.unix(datetimeVal).format("YYYY-MM-DD");
  return time;
};

export const filterBalance = (balances, asset) => {
  const balance = Object.keys(balances).filter(
    (b) => balances[b].currency === asset
  )[0];
  return balances[balance];
};

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

function clearDateNumber(value = "") {
  const noSlash20 = value.replace(/\D+20/g, "");
  return clearNumber(noSlash20);
}

export const formatCreditCardNumber = (value) => {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
};

export const formatCVC = (value, prevValue, allValues = {}) => {
  const clearValue = clearNumber(value);
  let maxLength = 3;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    console.log(issuer);
  }

  return clearValue.slice(0, maxLength);
};

export const formatExpirationDate = (value) => {
  const clearValue = clearDateNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/20${clearValue.slice(2, 4)}`;
  }

  return clearValue;
};

export const shortenText = (value) => {
  if (!value) {
    return "";
  }
  return (
    value.slice(0, 8) + "...." + value.slice(value.length - 8, value.length)
  );
};

export const shortAddress = (address = "", number = 24) => {
  const { length } = address;
  return `${address.slice(0, number)}...${address.slice(length - 4, length)}`;
};

export const transformQuotes = (quotes) => {
  quotes.forEach((element) => {
    if (element.open > element.close) {
      element.price = element.open - (element.open - element.close) * 10;
    } else {
      element.price = element.close + (element.close - element.open) * 10;
    }
  });
  return quotes;
};

export const getTheme = () => {
  const mode = _.get(
    document.getElementsByTagName("body"),
    "[0].classList[0]"
  )?.valueOf();
  const isDarkMode = mode === "dark-mode";
  return isDarkMode ? "dark" : "light";
};
