import { useQueries } from "@tanstack/react-query";
import _ from "lodash";

import { MARKET_PATH } from "src/api/path";

import { getQuotes } from "../api/market";

import { useGetStore } from "./useStore";
import { useRematchDispatch } from "./useRematchDispatch";

export const cal24hRate = (last_price: number, change_24: number) => {
  if (last_price === 0) return 0;
  const rate = (change_24 / last_price) * 100;
  return rate.toFixed(2);
};

export const useGetMarkets = () => {
  const market = useGetStore("market");

  const loading = useGetStore("loading");
  const marketLoading = _.get(loading, "models.market.loading", true);

  const markets = _.get(market, "markets", {});
  const hotMarkets = _.get(market, "hotMarkets", []);

  return {
    markets,
    marketLoading,
    hotMarkets,
  };
};
export const useFetchMarkets = () => {
  const { fetchAllMarkets } = useRematchDispatch((dispatch) => ({
    fetchAllMarkets: dispatch.market.fetchAllMarkets,
  }));

  return {
    fetchAllMarkets,
  };
};

export const useGetSimpleQuotes = ({ marketIds }: { marketIds: string[] }) => {
  const resolution = "1h";
  const now = Math.floor(Date.now() / 1000);
  const from = now - 84000;
  return useQueries({
    queries: marketIds.map((id) => {
      return {
        queryKey: [MARKET_PATH.QUOTE, id],
        queryFn: () => getQuotes(id, resolution, from, now),
      };
    }),
  });
};
