import _ from "lodash";

import { queryClient } from "..";
import apiClient, { addToken } from "../middlewares/api-client";
import { store } from "../stores";

/**
 * Refresh the access token and retrieve user data.
 */
export const refreshToken = async () => {
  const state = store.getState();
  if (state.user?.tokenData) {
    const refresh_token = _.get(state, "user.tokenData.refresh_token");

    const tokenResponse = await apiClient.post("user/refresh/", {
      refresh_token,
    });
    const tokenData = tokenResponse.data || {};
    const { access_token } = tokenData;

    try {
      const response = await apiClient.post(
        "/user/profile/full/",
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      const { message: userInfo } = response.data || {};

      if (userInfo.is_merchant) {
        logout();
        return;
      }
      store.dispatch.user.update({
        userInfo,
        tokenData,
      });
      addToken(access_token);
    } catch (e) {
      logout();
    }
  }
};

export const logout = () => {
  store.dispatch.user.logout();
  queryClient.clear();
};
