import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import { useRematchDispatch } from "src/hooks";
import { formatter } from "src/utils";

import styles from "./Main.module.sass";

const Main = ({ usdPrices }) => {
  const btcBalance = useSelector((rootState) => rootState.balance?.btcBalance);
  const [headerItems, setHeaderItems] = useState([]);
  const { fetchBalance } = useRematchDispatch((dispatch) => ({
    fetchBalance: dispatch.balance.fetchBalance,
  }));
  useEffect(() => {
    if (btcBalance && usdPrices) {
      const btcUsdPriceUnit = usdPrices?.find((b) => b.short_name === "tBTC");
      setHeaderItems([
        {
          title: "Spot balance",
          content: btcBalance,
          currency: "tBTC",
          price: formatter.format(btcUsdPriceUnit?.USD * btcBalance),
        },
        {
          title: "Yesterday's PNL",
          content: "0.00000000",
          currency: "tBTC",
          price: "$0",
        },
      ]);
    }
  }, [btcBalance, usdPrices]);

  useEffect(() => {
    fetchBalance();
  }, []);

  return (
    <div className={styles.main}>
      <h4 className={cn("h4", styles.title)}>Fiat and Spot</h4>
      <div className={styles.list}>
        {headerItems.map((x, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.info}>{x.title}</div>
            <div className={styles.line}>
              <div className={styles.currency}>{x.content}</div>
              <div className={cn("category-green", styles.category)}>
                {x.currency}
              </div>
            </div>
            <div className={styles.price}>{x.price}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Main;
