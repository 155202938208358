import _ from "lodash";
import { toast } from "react-toastify";
import useDarkMode from "use-dark-mode";

import { getTheme } from "src/utils";
import { ToastMessage, ToastMessageProps } from "src/components/Toast";

export const useToast = () => {
  const darkMode = useDarkMode(false);

  const toastProps: any = {
    hideProgressBar: true,
    theme: darkMode.value ? "dark" : "light",
  };

  return {
    toastSuccess: (props: ToastMessageProps) =>
      toast(<ToastMessage {...props} type="success" />, { ...toastProps }),
    toastInfo: (props: ToastMessageProps) =>
      toast(<ToastMessage {...props} type="info" />, { ...toastProps }),
    toastError: (props: ToastMessageProps) =>
      toast(<ToastMessage {...props} type="error" />, { ...toastProps }),
    toastWarning: (props: ToastMessageProps) =>
      toast(<ToastMessage {...props} type="warning" />, { ...toastProps }),
  };
};

// Use this without component
export const toastSuccess = (props: ToastMessageProps) => {
  const toastProps: any = {
    hideProgressBar: true,
    theme: getTheme(),
  };
  return toast(<ToastMessage {...props} type="success" />, { ...toastProps });
};

export const toastInfo = (props: ToastMessageProps) => {
  const toastProps: any = {
    hideProgressBar: true,
    theme: getTheme(),
  };
  return toast(<ToastMessage {...props} type="info" />, { ...toastProps });
};

export const toastError = (props: ToastMessageProps) => {
  const toastProps: any = {
    hideProgressBar: true,
    theme: getTheme(),
  };
  return toast(<ToastMessage {...props} type="error" />, { ...toastProps });
};

export const toastWarning = (props: ToastMessageProps) => {
  const toastProps: any = {
    hideProgressBar: true,
    theme: getTheme(),
  };
  return toast(<ToastMessage {...props} type="warning" />, { ...toastProps });
};

// Handle error when call api
export const useHandleError = () => {
  const { toastError } = useToast();

  const handleError = (errors: any) => {
    const message = _.get(errors, "message");
    toastError({ description: message });
  };

  return {
    handleError,
  };
};
