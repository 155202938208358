import React, { useEffect, useState } from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { useGetDataExchange } from "src/hooks/useExchange";

import Dropdown from "src/components/Dropdown";

import { useRematchDispatch } from "src/hooks";

import styles from "./OrderBook.module.sass";
import OrderBookOrdering from "./OrderBookOrdering";

const sorting = [
  {
    color1: "#FF6838",
    color2: "#B1B5C3",
    color3: "#58BD7D",
  },
  {
    color1: "#B1B5C3",
    color2: "#B1B5C3",
    color3: "#58BD7D",
  },
  {
    color1: "#FF6838",
    color2: "#B1B5C3",
    color3: "#B1B5C3",
  },
];

const counterOptions = ["10", "20", "30"];

const OrderBook = ({ marketId }) => {
  const { orderBook } = useSelector((rootState) => rootState.market);
  const { fetchOrderBook } = useRematchDispatch((dispatch) => ({
    fetchOrderBook: dispatch.market.fetchOrderBook,
  }));

  const [counter, setCounter] = useState(counterOptions[0]);
  const [buys, setBuys] = useState(orderBook?.buy || []);
  const [sells, setSells] = useState(orderBook?.sell || []);
  const [orderingIndex, serOrderingIndex] = useState(0);

  const { quoteCurrencyCode, baseCurrencyCode } = useGetDataExchange();

  useEffect(() => {
    if (marketId) {
      fetchOrderBook(marketId);
    }
  }, [marketId]);

  useEffect(() => {
    setBuys(orderBook?.buy.slice(0, counter));
    setSells(orderBook?.sell.slice(0, counter));
  }, [counter, orderBook]);

  // useEffect(() => {}, [orderingIndex]);

  return (
    <div className={styles.balance}>
      <div className={styles.head}>
        <div className={styles.sorting}>
          {sorting.map((x, index) => (
            <button
              className={cn(styles.direction, {
                [styles.active]: index === orderingIndex,
              })}
              key={index}
              onClick={() => {
                serOrderingIndex(index);
              }}
            >
              <span style={{ backgroundColor: x.color1 }}></span>
              <span style={{ backgroundColor: x.color2 }}></span>
              <span style={{ backgroundColor: x.color3 }}></span>
            </button>
          ))}
        </div>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          classDropdownArrow={styles.dropdownArrow}
          classDropdownBody={styles.dropdownBody}
          classDropdownOption={styles.dropdownOption}
          value={counter}
          setValue={setCounter}
          options={counterOptions}
        />
      </div>
      <div className={styles.top}>
        <div className={styles.price}>Price ({quoteCurrencyCode})</div>
        <div className={styles.amount}>Amounts ({baseCurrencyCode})</div>
        <div className={styles.total}>Total</div>
      </div>
      <div className={styles.list}>
        <OrderBookOrdering
          buys={buys}
          sells={sells}
          price={orderBook?.price}
          totalBuy={orderBook?.totalBuy}
          totalSell={orderBook?.totalSell}
          orderingType={orderingIndex}
        />
      </div>
    </div>
  );
};

export default OrderBook;
