import React, { useState, useRef, useEffect } from "react";
// import { Link } from "react-router-dom";
import cn from "classnames";

// import TradingViewWidget, { Themes, Datafeeds } from "react-tradingview-widget";
import useDarkMode from "use-dark-mode";
import Chart, { Theme } from "src/components/Chart";
import Dropdown from "src/components/Dropdown";
import DepthCharts from "src/components/DepthCharts";

import styles from "./Charts.module.sass";

const dates = ["15m", "1h"]; //"4h"
const navigation = ["Trading view", "Depth"];

const Charts = ({ market }) => {
  const [activeIndexDates, setActiveIndexDates] = useState(0);
  const [activeIndexNav, setActiveIndexNav] = useState(0);
  const [date, setDate] = useState(dates[0]);
  const chartContainerRef = useRef(null);
  const [chartHeight, setChartHeight] = useState(0);

  useEffect(() => {
    setChartHeight(chartContainerRef?.current?.clientHeight);
  }, [chartContainerRef]);

  const darkMode = useDarkMode(false);

  return (
    <div className={styles.charts}>
      <div className={styles.head}>
        <div className={styles.group}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={date}
            setValue={setDate}
            options={dates}
          />
          <div className={styles.nav}>
            {dates.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndexDates,
                })}
                onClick={() => setActiveIndexDates(index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndexNav,
              })}
              onClick={() => setActiveIndexNav(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
      </div>
      {activeIndexNav === 0 && (
        <div className={styles.inner}>
          <div className={styles.iframe} ref={chartContainerRef}>
            {market && (
              <Chart
                height={chartHeight}
                marketId={market?.id}
                resolution={dates[activeIndexDates]}
                theme={darkMode.value ? Theme.DARK : Theme.LIGHT}
              />
            )}

            {/* <TradingViewWidget
              symbol="BINANCE:BTCUSDT"
              theme={darkMode.value ? Themes.DARK : Themes.LIGHT}
              locale="en"
              hide_top_toolbar={true}
              studies={["Volume@tv-basicstudies"]}
              autosize
            /> */}
          </div>
        </div>
      )}
      {activeIndexNav === 1 && (
        <div className={styles.chart}>
          <DepthCharts />
        </div>
      )}
    </div>
  );
};

export default Charts;
