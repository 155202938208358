import React, { useState } from "react";
import _ from "lodash";
import QRCode from "react-qr-code";

import Modal from "src/components/Modal";
import { useGetDepositAddress } from "src/hooks/useWallet";
import Loader from "src/components/Loader";
import { useToast } from "src/hooks/useToast";
import Copy from "src/components/Copy";
import { shortAddress } from "src/utils";

import styles from "./Deposit.module.sass";

type Props = {
  assetCode: string;
};

const Deposit = ({ assetCode }: Props) => {
  const [visible, setVisible] = useState(false);
  const [address, setAddress] = useState("");
  const { toastWarning } = useToast();

  const { mutateAsync, isLoading } = useGetDepositAddress();

  const handleToggleDeposit = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const res = await mutateAsync(assetCode);
    const newAddress = _.get(res, "data.message.address");
    if (!newAddress) {
      toastWarning({
        title: "Not available",
        description: "Deposit is not available",
      });
      return;
    }
    setAddress(newAddress);
    setVisible(true);
  };

  return (
    <React.Fragment>
      <div className={styles.button} onClick={handleToggleDeposit}>
        {isLoading ? <Loader className="m-r-10" /> : "Deposit"}
      </div>
      {/* @ts-ignore */}
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <div>Deposit {assetCode}</div>
        <div className={styles.container_qr}>
          <QRCode className={styles.qr_code} value={address || ""} />
        </div>
        <div className="m-t-25">
          <div className={styles.address_label}>
            {assetCode} Deposit Address
          </div>
          <div>
            <span className="m-r-10">{shortAddress(address)}</span>
            <Copy value={address} />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Deposit;
