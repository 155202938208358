import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  ERROR_ENABLING_2FA,
  MFA_ENABLED,
  MFA_DISABLED,
  MFA_REQUEST,
  ERROR_OTP_REQUEST,
  OTP_NOT_MATCHING,
} from "src/constants/message";

import { RootState } from "../../stores/store";
import Profile from "../../components/Profile";
import { useToast } from "../../hooks/useToast";

import { useRematchDispatch } from "../../hooks";

import TwoFaEnabled from "./TwoFaEnabled";
import TwoFaDisabled from "./TwoFaDisabled";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "2FA",
  },
];

const TwoFa = () => {
  const [disabled, setDisabled] = useState(true);
  const userInfo = useSelector(
    (rootState: RootState) => rootState.user?.userInfo
  );
  const { toastSuccess, toastInfo, toastError } = useToast();

  const { enableMFARequest } = useRematchDispatch((dispatch) => ({
    enableMFARequest: dispatch.user.enableMFA,
  }));

  const enableMFA = async () => {
    const isSuccess = await enableMFARequest();
    if (isSuccess) {
      toastSuccess({
        title: MFA_ENABLED.title,
        description: MFA_ENABLED.message,
      });
      setDisabled(false);
    } else {
      toastError({
        title: ERROR_ENABLING_2FA.title,
        description: ERROR_ENABLING_2FA.message,
      });
    }
  };

  const { disableMFARequest } = useRematchDispatch((dispatch) => ({
    disableMFARequest: dispatch.user.disableMFA,
  }));

  const disableMFA = async (data: any) => {
    const isSuccess = await disableMFARequest(data.otp);
    if (isSuccess) {
      toastInfo({
        description: MFA_DISABLED,
      });
      setDisabled(true);
    } else {
      toastError({
        title: OTP_NOT_MATCHING.title,
        description: OTP_NOT_MATCHING.message(data.otp),
      });
    }
  };

  const { getOTPRequest } = useRematchDispatch((dispatch) => ({
    getOTPRequest: dispatch.user.disableMFARequest,
  }));

  const getOTP = async () => {
    const isSuccess = await getOTPRequest();

    if (isSuccess) {
      toastSuccess({
        description: MFA_REQUEST,
      });
    } else {
      toastError({
        title: ERROR_OTP_REQUEST.title,
        description: ERROR_OTP_REQUEST.message,
      });
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (userInfo) {
      // @ts-ignore
      setDisabled(!userInfo?.mfa_enabled);
    }
  }, [userInfo]);

  return (
    // @ts-ignore
    <Profile title="2FA" breadcrumbs={breadcrumbs}>
      {disabled ? (
        <TwoFaDisabled enableMFA={enableMFA} userInfo={userInfo} />
      ) : (
        <TwoFaEnabled
          goDisabled={(data: any) => disableMFA(data)}
          userInfo={userInfo}
          getOTP={() => getOTP()}
        />
      )}
    </Profile>
  );
};

export default TwoFa;
