import { fetchData } from "src/hooks/customHooks";

import { USER_PATH } from "./path";

type Session = {
  id: string;
  user_id: string;
  status: string;
  expires_at: string;
  ip_address: string;
  user_agent: string;
  token: string;
  refresh_token: string;
  sessionid: string;
};

type Response<T = any> = {
  items: T[];
};

export const getSessions = (): Promise<Response<Session>> => {
  const data = fetchData(`${USER_PATH.SESSIONS}`, {}).then((response) =>
    response.sort((a: Session, b: Session) =>
      b.expires_at.localeCompare(a.expires_at)
    )
  );
  return data;
};
