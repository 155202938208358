import "./styles/app.sass";
import cookie from "cookie";

import AppRoutes from "./routes";
function App() {
  return <AppRoutes />;
}

App.getInitialProps = async (context) => {
  const appProps = await App.getInitialProps(context);

  const cookies = cookie.parse(
    context.ctx.req ? context.ctx.req.headers.cookie || "" : document.cookie
  );

  return {
    ...appProps,
    cookies,
  };
};
export default App;
