import React, { useEffect } from "react";

import { useFetchMarkets } from "src/hooks/useMarket";
import Lessons from "src/components/Lessons";

import Main from "./Main";
import Details from "./Details";

const Market = () => {
  const { fetchAllMarkets } = useFetchMarkets();

  useEffect(() => {
    fetchAllMarkets();
  }, [fetchAllMarkets]);

  return (
    <>
      <Main />
      <Details />
      {/* <Lessons classSection="section" /> */}
    </>
  );
};

export default Market;
