import { useState } from "react";
import cn from "classnames";

import Dropdown from "src/components/Dropdown";

import styles from "./Table.module.sass";
import OpenOrders from "./OpenOrders";
import MyTrades from "./MyTrades";

const navigation = ["Open orders", "My trades"]; //"My trades", "Open orders", "Favorites";

const Table = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(navigation[0]);

  return (
    <div className={styles.inner}>
      {/* @ts-ignore */}
      <Dropdown
        className={styles.dropdown}
        value={sorting}
        setValue={setSorting}
        options={navigation}
      />
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      {activeIndex === 0 ? <OpenOrders /> : <MyTrades />}
    </div>
  );
};

export default Table;
