import Support from "../../components/Support";

import Content from "./Content";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Account Information",
  },
];

const AccountInformation = () => {
  return (
    <Support title="Account Information" breadcrumbs={breadcrumbs}>
      <Content />
    </Support>
  );
};

export default AccountInformation;
