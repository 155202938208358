import React, { useEffect, useState } from "react";
import cn from "classnames";

import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { useGetPrice } from "src/hooks/useFiat";
import { useGetUserInfo } from "src/hooks/useUser";
import { RoutePath } from "src/routes/path";

import Icon from "../Icon";
import { useRematchDispatch } from "../../hooks";

import styles from "./SelectCrypto.module.sass";

const SelectCrypto = ({ goNext }) => {
  const [search, setSearch] = useState("");
  const assets = useSelector((rootState) => rootState.asset?.assets);
  const assetDispatch = useRematchDispatch((dispatch) => dispatch.asset);
  const fiatDispatch = useRematchDispatch((dispatch) => dispatch.fiat);
  const { data } = useGetPrice();
  const [prices, setPrices] = useState([]);

  const history = useHistory();
  const { isAuth } = useGetUserInfo();

  useEffect(() => {
    if (data) {
      setPrices(data.data.message);
    }
  }, [data]);

  const imgBasePath = "/images/content/currency/";
  useEffect(() => {
    assetDispatch.fetchAssets();
  }, []);

  const handleSubmit = () => {
    alert();
  };

  const handleGoNext = (asset) => {
    if (isAuth) {
      fiatDispatch.setAsset(asset.short_name);
      goNext();
    } else {
      history.push(RoutePath.SIGN_IN);
    }
  };

  return (
    <div className={styles.crypto}>
      <div className={styles.title}>Select crypto</div>
      <form className={styles.form} action="" onSubmit={() => handleSubmit()}>
        <input
          className={styles.input}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder="Search coin"
          required
        />
        <button className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">#</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Name</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Price</div>
          </div>
        </div>
        {assets?.map((x, index) => {
          const priceObj = prices?.find((e) => e.asset_code === x.short_name);
          console.log("price: ", priceObj);
          return (
            <div
              className={styles.row}
              key={index}
              onClick={() => handleGoNext(x)}
            >
              <div className={styles.col}>
                <div className={styles.line}>
                  <button className={cn("favorite", styles.favorite)}>
                    <Icon name="star-outline" size="16" />
                  </button>
                  {index + 1}
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.item}>
                  <div className={styles.icon}>
                    <img src={imgBasePath + x.short_name + ".svg"} alt="Coin" />
                  </div>
                  <div className={styles.details}>
                    <span className={styles.subtitle}>{x.long_name}</span>
                    <span className={styles.currency}>{x.short_name}</span>
                  </div>
                </div>
              </div>
              <div className={styles.col}>{priceObj?.price || 0}</div>
              {/* <div className={styles.col}>
              {x.positiveDay && (
                <div className={styles.positive}>{x.positiveDay}</div>
              )}
              {x.negativeDay && (
                <div className={styles.negative}>{x.negativeDay}</div>
              )}
            </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectCrypto;
