import { createModel } from "@rematch/core";

import apiClient from "../../middlewares/api-client";

import { RootModel } from ".";

type Nullable<T> = T | null;
interface Currency {
  currency: string;
  name: string;
}
interface Asset {
  asset_id: string;
  asset_code: string;
  external_id: string;
}
interface Fees {
  transaction_fee: number;
  convert_fee: number;
}
interface PaymentOption {
  paymentOptionId: string;
  name: string;
  logo: string;
  extraData: any[];
  banks: Bank[];
}
interface Bank {
  id: string;
  name: string;
  logo: string;
}
interface Card {
  cardnum: string;
  expiryyear: string;
  expirymonth: string;
  cardcvv: string;
  cardholder: string;
}
interface PaymentPayload {
  session_id: Nullable<string>;
  payment_option_id: Nullable<string>;
  bank_id: Nullable<string>;
  extra_data: Nullable<Card>;
}
export interface FiatBalanceState {
  // permanentInfo: any[]
  balances: any;
  usdBalance: any;
  currencies: Currency[];
  assets: Asset[];
  currency: string;
  amount: number;
  asset: string;
  assetAmount: number;
  paymentOptions: PaymentOption[];
  sessionId: string;
  card: Nullable<Card>;
  paymentOptionId: Nullable<string>;
  paymentOptionName: Nullable<string>;
  fees: Nullable<Fees>;
  paymentTransactionId: string;
  paymentStatus: string;
}

const initState = {
  // permanentInfo: [],
  balances: undefined,
  usdBalance: undefined,
  assets: [],
  currencies: [],
  amount: 0,
  asset: "",
  assetAmount: 0,
  currency: "",
  paymentOptions: [],
  sessionId: "",
  card: null,
  paymentOptionId: "",
  paymentOptionName: "",
  fees: null,
  paymentTransactionId: "",
  paymentStatus: "waiting",
} as FiatBalanceState;

export const fiat = createModel<RootModel>()({
  state: initState,
  reducers: {
    setBalances(state, balance) {
      state.balances = balance;
      const usdIndex = Object.keys(balance).filter(
        (b) => balance[b].currency_code === "USD"
      )[0];
      state.usdBalance = balance[usdIndex]?.balance;
    },
    setCurrencies(state, currencies) {
      state.currencies = currencies;
    },
    setAssets(state, assets) {
      state.assets = assets;
    },
    setAsset(state, asset) {
      state.asset = asset;
    },
    setAmount(state, amount) {
      state.amount = amount;
    },
    setCurrency(state, currency) {
      state.currency = currency;
    },
    setAssetAmount(state, amount) {
      state.assetAmount = amount;
    },
    setSessionId(state, sessionId) {
      state.sessionId = sessionId;
    },
    setPaymentOptions(state, paymentOptions) {
      state.paymentOptions = paymentOptions;
    },
    setCard(state, card) {
      state.card = card;
    },
    setPaymentOptionId(state, paymentOptionId) {
      state.paymentOptionId = paymentOptionId;
    },
    setPaymentOptionName(state, paymentOptionName) {
      state.paymentOptionName = paymentOptionName;
    },
    setFees(state, fees) {
      state.fees = fees;
    },
    setPaymentTransactionId(state, transactionId) {
      state.paymentTransactionId = transactionId;
    },
    setPaymentStatus(state, status) {
      state.paymentStatus = status;
    },
    reset() {
      return initState;
    },
  },
  effects: () => ({
    async fetchBalance() {
      const response = await apiClient.post("fiat/balance/");
      const { message: balance } = response.data || [];
      this.setBalances(balance);
      return true;
    },
    async fetchCurrencies() {
      const response = await apiClient.post("fiat/currencies/");
      const { message: currencies } = response.data || [];
      this.setCurrencies(currencies);
      return true;
    },
    async fetchAssets() {
      const response = await apiClient.post("fiat/asset/all/");
      const { message: assets } = response.data || [];
      this.setAssets(assets);
      return true;
    },
    async fetchPaymentOptions({ amount, asset, currency }) {
      const response = await apiClient.post("fiat/payment-options/", {
        amount,
        asset,
        currency,
      });
      const { message: po } = response.data || [];
      this.setSessionId(po.sessionId);
      this.setPaymentOptions(po.paymentOptions);
      this.setFees(po.fees);
      return true;
      //TODO: handle error here
    },
    async submitPayment(_input, state) {
      const payload: PaymentPayload = {
        session_id: state.fiat.sessionId,
        payment_option_id: state.fiat.paymentOptionId,
        bank_id: null,
        extra_data: state.fiat.card,
      };
      const response = await apiClient.post("fiat/payment/", payload);
      const { message: po } = response.data || [];
      this.setPaymentStatus(po.resultType);
      if (po.resultType === "success" || po.resultType === "redirect") {
        this.setPaymentTransactionId(po.result.transactionId);
        return true;
      }
      return false;
    },
  }),
});
