import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useRematchDispatch } from "../../hooks";

import Profile from "../../components/Profile";

import styles from "./ProfileInfo.module.sass";
import UpdateProfileLv1 from "./ProfileLV1/UpdateProfileLV1";
import UpdateProfileLv2 from "./ProfileLV2/UpdateProfileLV2";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Profile info",
  },
];

const ProfileInfo = () => {
  const { fetchProfile } = useRematchDispatch((dispatch) => ({
    fetchProfile: dispatch.user.fetchProfile,
  }));

  const userInfo = useSelector((rootState) => rootState.user?.userInfo);

  const defaultValues = {
    firstName: userInfo?.first_name || "",
    middleName: userInfo?.middle_name || "",
    lastName: userInfo?.last_name || "",
    country: parseInt(userInfo?.country?.country_id) || "",
    timezone: userInfo?.timezone?.timezone_id || "",
    street1: userInfo?.street1 || "",
    street2: userInfo?.street2 || "",
    postalcode: userInfo?.postal_code || "",
    state: userInfo?.state || "",
    city: userInfo?.city || "",
    phone: userInfo?.phone || "",
  };

  const { fetchCountryList } = useRematchDispatch((dispatch) => ({
    fetchCountryList: dispatch.common.fetchCountryList,
  }));

  const { fetchTimezoneList } = useRematchDispatch((dispatch) => ({
    fetchTimezoneList: dispatch.common.fetchTimezoneList,
  }));

  useEffect(() => {
    fetchProfile().then();
    fetchCountryList().then();
    fetchTimezoneList().then();
  }, []);
  return (
    <Profile title="Profile info" breadcrumbs={breadcrumbs}>
      <div className={styles.head}>
        <div className={styles.details}>
          <div className={styles.user}>
            {userInfo.first_name} {userInfo.last_name}
          </div>
          <div className={styles.email}>{userInfo.email}</div>
          {userInfo.verified_email && (
            <div className={styles.emailVerified}>Email verified</div>
          )}
          {!userInfo.verified_email && (
            <div className={styles.emailNotVerified}>Email not verified</div>
          )}
        </div>
      </div>
      <div className={styles.box}>
        {<UpdateProfileLv1 defaultValues={defaultValues}></UpdateProfileLv1>}
      </div>
      <div className={styles.box}>
        <div className={styles.subtitle}>Level 2</div>
        {<UpdateProfileLv2 defaultValues={defaultValues}></UpdateProfileLv2>}
      </div>
    </Profile>
  );
};

export default ProfileInfo;
