import React, { useEffect, useState } from "react";
import cn from "classnames";

import apiClient from "../../../middlewares/api-client";

import styles from "./ImportantNotes.module.sass";

const ImportantNotes = ({ goNext, depositDetail }) => {
  const [convertRate, setConvertRate] = useState(
    `${depositDetail.symbol}${depositDetail.amount}`
  );
  const [usdAmount, setUsdAmount] = useState(depositDetail.amount);
  const setConvertRateDetail = async () => {
    if (depositDetail.currency && depositDetail.currency !== "USD") {
      const rateResponse = await apiClient.post("/fiat/rate/", {
        amount: parseInt(depositDetail.amount),
        from_currency: depositDetail.currency,
      });
      const fixdRate = rateResponse.data.message.result.toFixed(2);
      setUsdAmount(fixdRate);
      const rate = `${depositDetail.symbol}${depositDetail.amount} > $${fixdRate}`;
      setConvertRate(rate);
    }
  };
  useEffect(() => {
    setConvertRateDetail();
  }, [depositDetail]);

  return (
    <div className={styles.item}>
      <div className={styles.title}>Important notes</div>
      <div className={styles.text}>We ONLY accept fundings from Kibramoa:</div>
      {/* <div className={styles.info}>Veum Cecilia</div> */}
      <div className={styles.text}>
        Your deposit fun will be converted to USD.
      </div>
      <div className={styles.text}>Convert rate:</div>
      <div className={styles.code}>{convertRate}</div>
      <div className={styles.btns}>
        <button
          className={cn("button", styles.button)}
          onClick={() => goNext(usdAmount)}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default ImportantNotes;
