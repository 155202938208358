import React, { useEffect, useState } from "react";
import cn from "classnames";

import { Link } from "react-router-dom";

import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import Transfer from "../../../components/Transfer";

import styles from "./AccountBalances.module.sass";

let items = [
  {
    title: "Fiat and Spot",
    color: "#FFD166",
    key: "fiat",
  },
  {
    title: "Margin",
    color: "#9757D7",
    soon: true,
    key: "margin",
  },
  {
    title: "P2P",
    color: "#4BC9F0",
    soon: true,
    key: "p2p",
  },
  {
    title: "Futures",
    color: "#3772FF",
    soon: true,
    key: "future",
  },
];

const AccountBalances = (props) => {
  const { btcBalance } = props;
  const [visibleTransfer, setVisibleTransfer] = useState(false);
  useEffect(() => {
    if (btcBalance?.length > 0) {
      items.map((i) => {
        for (const b of btcBalance) {
          if (b.key === i.key) {
            i.currency = `${b.amount} tBTC`;
            i.price = `${b.usdPrice}`;
            return i;
          }
          return i;
        }
      });
    }
  }, btcBalance);
  return (
    <>
      <div className={styles.list}>
        {items.map((x, index) => (
          <div
            className={cn(styles.item, { [styles.soon]: x.soon })}
            key={index}
          >
            <div className={styles.head}>
              <div className={styles.title}>
                <div
                  className={styles.bg}
                  style={{ backgroundColor: x.color }}
                ></div>
                {x.title}
              </div>
              <div className={styles.details}>
                <div className={styles.currency}>{x.currency}</div>
                <div className={styles.price}>{x.price}</div>
              </div>
            </div>
            <div className={styles.body}>
              {x.soon ? (
                <div className={styles.soon}>Coming soon</div>
              ) : (
                <div className={styles.btns}>
                  <Link
                    className={cn("button-stroke button-small", styles.button)}
                    to="/deposit-fiat"
                  >
                    <span>Deposit</span>
                    <Icon name="arrow-right" size="16" />
                  </Link>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={() => setVisibleTransfer(true)}
                  >
                    Transfer
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <Transfer />
      </Modal>
    </>
  );
};

export default AccountBalances;
