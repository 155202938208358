import { createModel } from "@rematch/core";

import apiClient from "../../middlewares/api-client";

import { RootModel } from ".";

export interface BalanceState {
  // permanentInfo: any[]
  balances: any;
  btcBalance: any;
}

const initState = {
  // permanentInfo: [],
  balances: undefined,
  btcBalance: undefined,
} as BalanceState;

export const balance = createModel<RootModel>()({
  state: initState,
  reducers: {
    setBalances(state, balance) {
      state.balances = balance;
      const btcIndex = Object.keys(balance).filter(
        (b) => balance[b].currency === "tBTC"
      )[0];
      state.btcBalance = balance[btcIndex].balance;
    },

    reset() {
      return initState;
    },
  },
  effects: () => ({
    async fetchBalance() {
      const response = await apiClient.post("balance/balances/");
      const { message: balance } = response.data || [];
      this.setBalances(balance);
      return true;
    },
  }),
});
