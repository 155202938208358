import Support from "../../components/Support";

import Content from "./Content";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Id Verification",
  },
];

const IdVerification = () => {
  return (
    <Support title="Id Verification" breadcrumbs={breadcrumbs}>
      <Content />
    </Support>
  );
};

export default IdVerification;
