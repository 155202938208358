import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

import { getPersistor } from "@rematch/persist";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ErrorBoundary } from "react-error-boundary";

import App from "./App";
import SplashScreen from "./components/SplashScreen";
import { store } from "./stores";

import { refreshToken } from "./api/authUtils";

import ErrorFallback from "./components/ErrorFallback";

const persist = getPersistor();
export const queryClient = new QueryClient();
const myErrorHandler = (error) => {
  console.log("error", error);
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={<SplashScreen />}
        persistor={persist}
        onBeforeLift={refreshToken}
      >
        <QueryClientProvider client={queryClient}>
          <ToastContainer />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={myErrorHandler}
          >
            <App />
          </ErrorBoundary>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
