import React from "react";
import { CircularProgress } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { useCancelOrder } from "src/hooks/useExchange";

type Props = {
  id: string;
  symbol: string;
};

const CancelOrderButton = ({ id, symbol }: Props) => {
  const { mutateAsync, isLoading } = useCancelOrder({ symbol });

  const handleCancelOrder = async () => {
    await mutateAsync(id);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <CircularProgress size={16} />
      ) : (
        <DeleteOutlineOutlinedIcon
          sx={{ cursor: "pointer" }}
          onClick={handleCancelOrder}
        />
      )}
    </React.Fragment>
  );
};

export default CancelOrderButton;
