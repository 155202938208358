import { Box, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";

import TextInput from "src/components/TextInput";

import {
  FormProvider,
  RHFTextField,
} from "../../../common/components/hook-form";
import { RootDispatch } from "../../../stores";
import { useRematchDispatch } from "../../../hooks";

import styles from "./ProfileLV2.module.sass";

type FormValuesProps = {
  city: string | null;
  phone: string | null;
  postalcode: string | null;
  state: string | null;
  street1: string | null;
  street2: string | null;
};

const UpdateProfileLv2 = ({ defaultValues }: { defaultValues: any }) => {
  const { updateUserLv2 } = useRematchDispatch((dispatch: RootDispatch) => ({
    updateUserLv2: dispatch.user.updateUserLv2,
  }));

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: any) => {
    try {
      await updateUserLv2(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider
      className={[]}
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          display: "grid",
          rowGap: 3,
          columnGap: 2,
          gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)" },
        }}
      >
        <Controller
          name="street1"
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <TextInput
              className={styles.field}
              label="Street1"
              type="text"
              {...field}
            />
          )}
        />
        <Controller
          name="street2"
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <TextInput
              className={styles.field}
              label="Street2"
              type="text"
              {...field}
            />
          )}
        />
        <Controller
          name="postalcode"
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <TextInput
              className={styles.field}
              label="Postal Code"
              type="text"
              {...field}
            />
          )}
        />
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <TextInput
              className={styles.field}
              label="State"
              type="text"
              {...field}
            />
          )}
        />
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <TextInput
              className={styles.field}
              label="City"
              type="text"
              {...field}
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <TextInput
              className={styles.field}
              label="Phone Number"
              type="text"
              {...field}
            />
          )}
        />
      </Box>

      <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
        <LoadingButton
          sx={{
            borderRadius: 12,
            paddingTop: 1.5,
            paddingLeft: 3,
            paddingRight: 3,
            paddingBottom: 1.5,
            textTransform: "none",
            fontWeight: 600,
          }}
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Save Changes
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export default UpdateProfileLv2;
