import { useQuery } from "@tanstack/react-query";

import {
  policyRequest,
  aboutRequest,
  careersRequest,
  developerToolsRequest,
  helpCenterRequest,
  contactUsRequest,
  idVerificationRequest,
  accountInformationRequest,
  userAgreementRequest,
  amlCftRequest,
  licensesRequest,
  feesRequest,
} from "src/api/cms";
import { CMS_PATH } from "src/api/path";

import { useHandleError } from "./useToast";

export const usePolicyRequest = () => {
  const { handleError } = useHandleError();

  return useQuery({
    queryKey: [CMS_PATH.POLICY],
    queryFn: () => policyRequest(),
    onError: (error) => handleError(error),
  });
};

export const useAboutRequest = () => {
  const { handleError } = useHandleError();

  return useQuery({
    queryKey: [CMS_PATH.ABOUT],
    queryFn: () => aboutRequest(),
    onError: (error) => handleError(error),
  });
};

export const useCareersRequest = () => {
  const { handleError } = useHandleError();

  return useQuery({
    queryKey: [CMS_PATH.CAREERS],
    queryFn: () => careersRequest(),
    onError: (error) => handleError(error),
  });
};

export const useDeveloperToolsRequest = () => {
  const { handleError } = useHandleError();

  return useQuery({
    queryKey: [CMS_PATH.DEVELOPER_TOOLS],
    queryFn: () => developerToolsRequest(),
    onError: (error) => handleError(error),
  });
};

export const useHelpCenterRequest = () => {
  const { handleError } = useHandleError();

  return useQuery({
    queryKey: [CMS_PATH.HELP_CENTER],
    queryFn: () => helpCenterRequest(),
    onError: (error) => handleError(error),
  });
};

export const useContactUsRequest = () => {
  const { handleError } = useHandleError();

  return useQuery({
    queryKey: [CMS_PATH.HELP_CENTER],
    queryFn: () => contactUsRequest(),
    onError: (error) => handleError(error),
  });
};

export const useIdVerificationRequest = () => {
  const { handleError } = useHandleError();

  return useQuery({
    queryKey: [CMS_PATH.ID_VERIFICATION],
    queryFn: () => idVerificationRequest(),
    onError: (error) => handleError(error),
  });
};

export const useAccountInformationRequest = () => {
  const { handleError } = useHandleError();

  return useQuery({
    queryKey: [CMS_PATH.ACCOUNT_INFORMATION],
    queryFn: () => accountInformationRequest(),
    onError: (error) => handleError(error),
  });
};

export const useUserAgreementRequest = () => {
  const { handleError } = useHandleError();

  return useQuery({
    queryKey: [CMS_PATH.USER_AGREEMENT],
    queryFn: () => userAgreementRequest(),
    onError: (error) => handleError(error),
  });
};

export const useAmlCftRequest = () => {
  const { handleError } = useHandleError();

  return useQuery({
    queryKey: [CMS_PATH.AML_CFT],
    queryFn: () => amlCftRequest(),
    onError: (error) => handleError(error),
  });
};

export const useLicensesRequest = () => {
  const { handleError } = useHandleError();

  return useQuery({
    queryKey: [CMS_PATH.LICENSES],
    queryFn: () => licensesRequest(),
    onError: (error) => handleError(error),
  });
};

export const useFeesRequest = () => {
  const { handleError } = useHandleError();

  return useQuery({
    queryKey: [CMS_PATH.FEES],
    queryFn: () => feesRequest(),
    onError: (error) => handleError(error),
  });
};
