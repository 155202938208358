import React, { useState } from "react";
import cn from "classnames";
import _ from "lodash";
import TextInput from "src/components/TextInput";
import LoadingButton from "src/components/LoadingButton";
import Icon from "src/components/Icon";
import { useWithdrawConfirm } from "src/hooks/useWallet";

import styles from "./Confirm.module.sass";

type Props = {
  registerId: string;
  onSuccess: (tx: string) => void;
};

const Confirm = ({ registerId, onSuccess }: Props) => {
  const [code, setCode] = useState("");
  const { mutateAsync: withdrawConfirm, isLoading } = useWithdrawConfirm();

  const handleSubmit = async () => {
    const res = await withdrawConfirm({
      otp_code: code,
      register_id: registerId,
    });
    const tx = _.get(res, "data.message.tx") || "";
    onSuccess(tx);
  };

  const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  return (
    <div>
      <div className={cn("h4", styles.title)}>
        <Icon name="arrow-left" size="32" />
        Confirm
      </div>
      {/* @ts-ignore */}
      <TextInput
        className={styles.field}
        label="OTP Code"
        name="amount"
        type="text"
        required
        onChange={handleChangeCode}
      />
      <LoadingButton
        className={cn("button", styles.button)}
        buttonText="Confirm"
        loading={isLoading}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default Confirm;
