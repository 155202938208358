import cn from "classnames";

import styles from "./Loader.module.sass";

type Props = {
  className?: string;
  color?: string;
};

const Loader = ({ className, color }: Props) => {
  return (
    <div
      className={cn(styles.loader, className, {
        [styles.loaderWhite]: color === "white",
      })}
    ></div>
  );
};

export default Loader;
