import cn from "classnames";

import { shortAddress } from "src/utils";
import Copy from "src/components/Copy";

import styles from "./Successfully.module.sass";
// import { Link } from "react-router-dom";

type Props = {
  address: string;
  assetCode: string;
  amount: number;
  tx: string;
};

const Successfully = ({ address, assetCode, amount, tx }: Props) => {
  const items = [
    {
      title: "Status",
      content: "Completed",
      color: "#58BD7D",
    },
    {
      title: "Transaction ID",
      content: shortAddress(tx, 10),
      copy: tx,
    },
    {
      title: "Address",
      content: shortAddress(address),
      copy: address,
    },
  ];

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Yay!{" "}
          <span role="img" aria-label="firework">
            🎉
          </span>
        </div>
        <div className={styles.info}>
          You successfully a withdrawal{" "}
          <span>
            {amount} {assetCode}
          </span>{" "}
          from Byta
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content} style={{ color: x.color }}>
                {x.content}
                {x.copy && <Copy value={x.copy} className="m-l-10" />}
              </div>
            </div>
          ))}
        </div>
        {/* <Link className={cn("button", styles.button)} to="/wallet-overview">
          Done
        </Link> */}
      </div>
    </>
  );
};

export default Successfully;
