import { createModel } from "@rematch/core";

import { toastWarning } from "src/hooks/useToast";
import { INCORRECT_USERNAME_PASSWORD } from "src/constants/message/auth";

import apiClient, { addToken, resetToken } from "../../middlewares/api-client";

import { RootModel } from ".";

export interface UserState {
  userInfo: undefined;
  tokenData: undefined;
}

const initState = {} as UserState;

export const user = createModel<RootModel>()({
  state: initState,
  reducers: {
    update(state, payload) {
      state.userInfo = payload.userInfo;
      state.tokenData = payload.tokenData;
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    reset() {
      return initState;
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  effects: (dispatch) => ({
    async login(user) {
      try {
        const formData = new FormData();
        formData.append("username", user.username);
        formData.append("password", user.password);
        const tokenResponse = await apiClient.post("user/login/", formData);
        const tokenData = tokenResponse.data || {};
        const { access_token } = tokenData;
        const response = await apiClient.post(
          "/user/profile/full/",
          {},
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        const { message: userInfo } = response.data || {};

        if (userInfo.is_merchant) {
          toastWarning({ description: INCORRECT_USERNAME_PASSWORD });
          return;
        }
        this.update({
          userInfo,
          tokenData,
        });
        addToken(access_token);
        console.log("return ");
        return true;
      } catch (e) {
        toastWarning({ description: INCORRECT_USERNAME_PASSWORD });
      }
    },
    async resetPassword(user) {
      const data = JSON.stringify({
        old_password: user.oldPassword,
        new_password: user.newPassword,
        new_password_repeat: user.confirmPassword,
      });
      const response = await apiClient.post(
        "/user/reset/password/auth_request/",
        data
      );
      return response;
    },
    async logout() {
      dispatch({ type: "RESET_APP" });
      resetToken();
    },
    async fetchProfile(_: void, rootState) {
      if (rootState.user.userInfo !== undefined) {
        return;
      }

      const response = await apiClient.post("/user/profile/full/", {});
      const { message: userInfo } = response.data || {};
      this.updateUserInfo(userInfo);
    },
    async refetchProfile() {
      const response = await apiClient.post("/user/profile/full/", {});
      const { message: userInfo } = response.data || {};
      this.updateUserInfo(userInfo);
    },
    async updateUserLv1(formData: any) {
      await apiClient.post("user/profile/save/level1/", {
        countryid: formData.countryid === "" ? -1 : formData.countryid, // Send -1 when no country is selected
        timezoneid: formData.timezoneid === "" ? -1 : formData.timezoneid,
        firstname: formData.firstname,
        lastname: formData.lastname,
        middlename: formData.middlename,
      });
      await this.refetchProfile(true);

      return true;
    },
    async updateUserLv2(formData: any) {
      await apiClient.post("user/profile/save/level2/", {
        city: formData.city,
        phone: formData.phone,
        postal_code: formData.postalcode,
        state: formData.state,
        street1: formData.street1,
        street2: formData.street2,
      });
      await this.refetchProfile(true);

      return true;
    },
    async kyc(file) {
      try {
        const data = new FormData();
        data.append("file", file);
        await apiClient.post("user/profile/save/level3/", data);
        await this.refetchProfile(true);

        return true;
      } catch (e) {
        return false;
      }
    },
    async uploadAvatar(file) {
      try {
        const data = new FormData();
        data.append("file", file);
        await apiClient.post("user/profile/picture/upload/", data);
        await this.refetchProfile(true);

        return true;
      } catch (e) {
        return false;
      }
    },
    async verifyMFACode(token) {
      try {
        const response = await apiClient.post("user/profile/verify", { token });
        await this.enableMFA();
        return true;
      } catch (e) {
        return false;
      }
    },
    async enableMFA() {
      try {
        const response = await apiClient.post("user/mfa/enable/", {});
        await this.refetchProfile(true);
        return true;
      } catch (e) {
        return false;
      }
    },
    async disableMFA(otp) {
      try {
        const response = await apiClient.post("user/mfa/disable/confirm/", {
          otp,
        });
        await this.refetchProfile(true);
        return true;
      } catch (e) {
        return false;
      }
    },
    async disableMFARequest() {
      try {
        const response = await apiClient.post("user/mfa/disable/request/", {});
        return true;
      } catch (e) {
        return false;
      }
    },
    async verifyOTPCode({ registerId, token }) {
      try {
        const response = await apiClient.post(
          "currency/merchant/withdraw/confirm/",
          {
            register_id: registerId,
            otp_code: token,
          }
        );
        return true;
      } catch (e) {
        return false;
      }
    },
  }),
});
