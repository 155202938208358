import React from "react";
import cn from "classnames";

import { Link } from "react-router-dom";
import { shortenText } from "src/utils";

import styles from "./Table.module.sass";

const Table = ({ className, items }) => {
  const imgBasePath = "/images/content/currency/";
  return (
    <div className={cn(className, styles.table)}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className="sorting">Type</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Coin</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Amount</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Address</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Transaction ID</div>
        </div>
        <div className={styles.col}>Date</div>
      </div>
      {items?.map((x, index) => (
        <Link className={styles.row} key={index} to={x.url}>
          <div className={styles.col}>
            {x.transaction_type === "withdrawal" && (
              <div className={cn("category-blue", styles.category)}>
                Withdrew
              </div>
            )}
            {x.transaction_type === "deposit" && (
              <div className={cn("category-green", styles.category)}>
                Deposited
              </div>
            )}
          </div>
          <div className={styles.col}>
            <div className={styles.item}>
              <div className={styles.icon}>
                <img src={imgBasePath + x.asset_code + ".svg"} alt="Coin" />
              </div>
              <div className={styles.currency}>{x.asset_code}</div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.label}>Amount</div>
            {x.amount}
          </div>
          <div className={styles.col}>
            <div className={styles.label}>Address</div>
            {shortenText(x.address)}
          </div>
          <div className={styles.col}>
            <div className={styles.label}>Transaction ID</div>
            {shortenText(x.tx_id)}
          </div>
          <div className={styles.col}>
            <div className={styles.label}>Date</div>
            {x.transaction_time}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Table;
