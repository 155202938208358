import { useGetDataExchange, useGetOpenOrders } from "src/hooks/useExchange";
import { formatNumber } from "src/utils";

import styles from "../Table.module.sass";

import CancelOrder from "./CancelOrderButton";

const OpenOrders = () => {
  const { symbol, baseCurrencyCode, quoteCurrencyCode, marketId } =
    useGetDataExchange();

  const { data } = useGetOpenOrders({ symbol, marketid: marketId });

  return (
    <div className={styles.table}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className="sorting">Time</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Action</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Amount ({baseCurrencyCode})</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Price ({quoteCurrencyCode})</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Total ({quoteCurrencyCode})</div>
        </div>
        <div className={styles.col}>
          <div>Delete</div>
        </div>
      </div>
      {data?.map((x: any, index: number) => (
        <div className={styles.row} key={index}>
          <div className={styles.col}>{x.create_date}</div>
          <div className={styles.col}>
            {x.side === "BUY" && <div className={styles.positive}>BUY</div>}
            {x.side === "SELL" && <div className={styles.negative}>SELL</div>}
          </div>
          <div className={styles.col}>{formatNumber(x.quantity)}</div>
          <div className={styles.col}>{formatNumber(x.price)}</div>
          <div className={styles.col}>{formatNumber(x.price * x.quantity)}</div>
          <div className={styles.col}>
            <CancelOrder
              id={x.id}
              symbol={`${baseCurrencyCode}${quoteCurrencyCode}`}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default OpenOrders;
