import React, { useState } from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
import {
  formatCurrency,
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../../../utils";
import { FormProvider } from "../../../common/components/hook-form";

import { useRematchDispatch } from "../../../hooks";
import Dropdown from "../../../components/Dropdown";

import styles from "./PaymentInfo.module.sass";

const cards = [
  {
    image: "./images/content/visa.svg",
    alt: "Visa",
  },
  {
    image: "./images/content/master-card.svg",
    alt: "Master Card",
  },
];

const PaymentInfo = ({ goNext, goBack }) => {
  const [save, setSave] = useState(true);
  const [paymentOptionsList, setPaymentOptionsList] = useState([]);
  const {
    asset,
    amount,
    assetAmount,
    currency,
    paymentOptions,
    paymentOptionName,
  } = useSelector((rootState) => rootState.fiat);
  const fiatDispatch = useRematchDispatch((dispatch) => dispatch.fiat);
  const options = [
    {
      title: "Spend",
      content: `${formatCurrency(amount, currency)}`,
      color: "#9757D7",
      icon: "wallet",
    },
    {
      title: "Get",
      content: `${assetAmount} ${asset}`,
      color: "#58BD7D",
      icon: "wallet",
    },
  ];

  const methods = useForm();
  const { handleSubmit, control } = methods;

  useState(() => {
    if (paymentOptions) {
      const _pmOptionList = paymentOptions?.map((p) => p.name);
      setPaymentOptionsList(_pmOptionList);
      if (_pmOptionList && _pmOptionList.length > 0)
        fiatDispatch.setPaymentOptionName(_pmOptionList[0]);
      fiatDispatch.setPaymentOptionId(paymentOptions[0].paymentOptionId);
    }
  }, [paymentOptions]);

  const handlePaymentSubmit = (value) => {
    const expiryyear = value.expirydate.split("/")[1];
    const expirymonth = value.expirydate.split("/")[0];
    fiatDispatch.setCard({
      cardnum: value.cardnum.replace(/\s/g, ""),
      cardholder: value.cardholder,
      cardcvv: value.cardcvv,
      expiryyear,
      expirymonth,
    });
    goNext();
  };
  const handleInputChange = ({ target }, onChange) => {
    if (target.name === "cardnum") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expirydate") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cardcvv") {
      target.value = formatCVC(target.value);
    }
    onChange(target.value);
  };

  const handleOptionChange = (val, index) => {
    const _pOption = paymentOptions[index];
    fiatDispatch.setPaymentOptionId(_pOption.paymentOptionId);
    fiatDispatch.setPaymentOptionName(val);
  };

  return (
    <FormProvider
      className={styles.form}
      methods={methods}
      onSubmit={handleSubmit(handlePaymentSubmit)}
    >
      <div className={styles.item}>
        <div className={styles.control}>
          <button className={styles.back} onClick={goBack}>
            <Icon name="arrow-prev" size="14" />
            Payment info
          </button>
          <div className={styles.money}>
            Buying {asset}
            <img src={`/images/content/currency/${asset}.svg`} alt="Coin" />
          </div>
        </div>
        <div className={styles.options}>
          {options.map((x, index) => (
            <div className={styles.option} key={index}>
              <div className={styles.icon} style={{ backgroundColor: x.color }}>
                <Icon name={x.icon} size="20" />
              </div>
              <div className={styles.details}>
                <div className={styles.category}>{x.title}</div>
                <div className={styles.content}>{x.content}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.line}>
          <div className={styles.subtitle}>Credit Card</div>
          <div className={styles.cards}>
            {cards.map((x, index) => (
              <div className={styles.card} key={index}>
                <img src={x.image} alt={x.alt} />
              </div>
            ))}
          </div>
        </div>

        <div className={styles.fieldset}>
          <div className={styles.field}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={paymentOptionName}
              setValue={handleOptionChange}
              options={paymentOptionsList}
              label="Select payment type"
            />
          </div>

          <Controller
            name="cardnum"
            control={control}
            render={({ field }) => (
              <TextInput
                className={styles.field}
                label="card number"
                name="cardnum"
                type="tel"
                placeholder="XXXX XXXX XXXX XXXX"
                required
                pattern="[\d| ]{16,22}"
                onChange={(val) => handleInputChange(val, field.onChange)}
              />
            )}
          />
          <Controller
            name="cardholder"
            control={control}
            render={({ field }) => (
              <TextInput
                className={styles.field}
                label="card holder"
                name="cardholder"
                type="text"
                placeholder="TRAN MAU TRI TAM"
                required
                onChange={(val) => handleInputChange(val, field.onChange)}
              />
            )}
          />
          <div className={styles.row}>
            <Controller
              name="expirydate"
              control={control}
              render={({ field }) => (
                <TextInput
                  className={styles.field}
                  label="EXPIRATION DATE"
                  name="expirydate"
                  type="tel"
                  placeholder="MM / YYYY"
                  pattern="\d{1,2}/\d{4}"
                  required
                  onChange={(val) => handleInputChange(val, field.onChange)}
                />
              )}
            />
            <Controller
              name="cardcvv"
              control={control}
              render={({ field }) => (
                <TextInput
                  className={styles.field}
                  label="CVC"
                  name="cardcvv"
                  type="password"
                  placeholder="CVC"
                  pattern="\d{3}"
                  required
                  onChange={(val) => handleInputChange(val, field.onChange)}
                />
              )}
            />
          </div>
        </div>
        <Checkbox
          className={styles.checkbox}
          value={save}
          onChange={() => setSave(!save)}
          content="Save Card"
        />
        <div className={styles.btns}>
          <button className={cn("button", styles.button)} type="submit">
            Continue
          </button>
        </div>
      </div>
    </FormProvider>
  );
};

export default PaymentInfo;
