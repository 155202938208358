export const ERROR_ENABLING_2FA = {
  title: "Error",
  message: "Error enabling 2FA",
};
export const MFA_ENABLED = {
  title: "Success",
  message: "2FA is enabled",
};
export const MFA_DISABLED = "2FA is disabled";
export const MFA_REQUEST = "Please check mail inbox for otp code.";
export const ERROR_OTP_REQUEST = {
  title: "Error",
  message: "Error sending 2fa code to your email",
};
export const OTP_NOT_MATCHING = {
  title: "Error",
  message: (otp: string) => `OTP code (${otp}) is not match`,
};
