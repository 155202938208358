import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";

import PasswordInput from "src/components/Password";
import Dropdown from "src/components/Dropdown";
import TextInput from "src/components/TextInput";
import LoadingButton from "src/components/LoadingButton";
import { useRematchDispatch } from "src/hooks";

import FormProvider from "../../../common/components/hook-form/FormProvider";

import styles from "./Form.module.sass";

const navigation = ["Email"];
const optionsPhone = ["🇺🇸 +1", "🇻🇳 +3", "🇷🇺 +7"];

const Form = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [phone, setPhone] = useState(optionsPhone[0]);

  const history = useHistory();
  const { login } = useRematchDispatch((dispatch) => ({
    login: dispatch.user.login,
  }));
  // const { fetchProfile } = useRematchDispatch((dispatch) => ({
  //   fetchProfile: dispatch.user.fetchProfile,
  // }));
  const methods = useForm();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const handleLogin = async (values) => {
    // fetchProfile()
    const result = await login(values);
    if (result) {
      history.push("/");
    }
  };

  return (
    <FormProvider
      className={styles.form}
      methods={methods}
      onSubmit={handleSubmit(handleLogin)}
    >
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
            type="button"
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.container}>
        {activeIndex === 0 && (
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextInput
                className={styles.field}
                label="username"
                name="username"
                type="username"
                placeholder="Username"
                required
                {...field}
              />
            )}
          />
        )}
        {activeIndex === 1 && (
          <div className={styles.line}>
            <div className={styles.field}>
              <Dropdown
                className={styles.dropdown}
                label="mobile"
                value={phone}
                setValue={setPhone}
                options={optionsPhone}
              />
            </div>
            <TextInput
              className={styles.field}
              name="phone"
              type="tel"
              required
            />
          </div>
        )}
      </div>
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <PasswordInput
            className={styles.field}
            label="Password"
            name="password"
            placeholder="Password"
            required
            view
            {...field}
          />
        )}
      />
      <div className={styles.foot}>
        {/* <button className={styles.scan} onClick={onScan && onScan()}>
          Scan to login
        </button> */}
        <Link className={styles.link} to="/forgot-password">
          Forgot password?
        </Link>
      </div>
      <LoadingButton
        className={cn("button", styles.button)}
        sx={{
          borderRadius: 12,
          padding: 1.5,
          marginTop: 4,
          textTransform: "none",
          fontWeight: 600,
        }}
        type="submit"
        variant="contained"
        loading={isSubmitting}
        buttonText="Login"
      />
    </FormProvider>
  );
};

export default Form;
