import React, { useEffect, useState } from "react";
import cn from "classnames";

import { useSelector } from "react-redux";
import { formatNumber } from "src/utils";
import { cal24hRate } from "src/hooks/useMarket";

import Icon from "../../../components/Icon";
import { useRematchDispatch } from "../../../hooks";

import styles from "./Main.module.sass";

const Main = ({ market }) => {
  const [headerItems, setHeaderItems] = useState([]);
  const { currentMarket, markets } = useSelector(
    (rootState) => rootState.market
  );
  const { fetchCurrentMarket } = useRematchDispatch((dispatch) => ({
    fetchCurrentMarket: dispatch.market.fetchCurrentMarket,
  }));

  const changeColor = (rate) => {
    if (rate >= 0) {
      return "#00C076";
    }
    return "#f66839";
  };

  useEffect(() => {
    fetchCurrentMarket(market);
  }, [market, markets]);

  useEffect(() => {
    setHeaderItems([
      {
        title: "24h change",
        content: `${currentMarket?.change_24} ${cal24hRate(
          currentMarket?.last_price,
          currentMarket?.change_24
        )}%`,
        icon: "clock",
        color: changeColor(currentMarket?.change_24),
      },
      {
        title: "24h high",
        content: `${currentMarket?.high_24}`,
        icon: "arrow-top",
      },
      {
        title: "24h low",
        content: `${currentMarket?.low_24}`,
        icon: "arrow-bottom",
      },
      {
        title: "24h volume",
        content: currentMarket?.volume_24,
        icon: "chart",
      },
    ]);
  }, [currentMarket]);

  return (
    <div className={styles.main}>
      <div className={styles.details}>
        <div className={styles.box}>
          <div className={styles.line}>
            <div className={styles.info}>
              {currentMarket?.base_currency_code}/
              {currentMarket?.quote_currency_code}
            </div>
            <div className={cn("category-green", styles.category)}>10x</div>
          </div>
          {/* <div className={styles.content}>Bitcoin</div> */}
        </div>
        <div className={styles.box}>
          <div className={styles.price}>
            {formatNumber(currentMarket?.last_price, 8)}
          </div>
          <div className={styles.content}>
            <Icon name="coin" size="16" />{" "}
            {formatNumber(currentMarket?.last_price, 8)}
          </div>
        </div>
      </div>
      <div className={styles.list}>
        {headerItems?.map((x, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.subtitle}>
              <Icon name={x.icon} size="16" />
              {x.title}
            </div>
            <div className={styles.content} style={{ color: x.color }}>
              {x.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Main;
