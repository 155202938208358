import { useSelector } from "react-redux";
import { RootState } from "src/stores";

type Key =
  | "common"
  | "user"
  | "wallet"
  | "balance"
  | "market"
  | "fiat"
  | "asset"
  | "loading";

export const useGetStore = (key: Key) => {
  return useSelector((rootState: RootState) => rootState[key]);
};
