import _ from "lodash";
import { useQuery } from "@tanstack/react-query";

import { getSessions } from "../api/user";

import { USER_PATH } from "../api/path";

import { useGetStore } from "./useStore";

export const useGetUserInfo = () => {
  const user = useGetStore("user");
  const userInfo = _.get(user, "userInfo");
  const tokenData = _.get(user, "tokenData");

  return {
    userInfo,
    tokenData,
    isAuth: !!(userInfo && tokenData),
  };
};

export const useGetSessions = () => {
  return useQuery({
    queryKey: [USER_PATH.SESSIONS],
    queryFn: () => getSessions(),
  });
};
