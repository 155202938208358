import { fetchData } from "src/hooks/customHooks";

import { MARKET_PATH } from "./path";

type Quote = {
  time: number;
  open: number;
  high: number;
  close: number;
  low: number;
  volume: number;
};

type Pagination = {
  items: number;
  pages: number;
  page: number;
  perpage: number;
};

type Response<T = any> = {
  items: T[];
  pagination: Pagination;
};

export const getQuotes = (
  marketID: string,
  resolution: string,
  from: number,
  to: number
): Promise<Response<Quote>> => {
  return fetchData(
    `${MARKET_PATH.QUOTE}${marketID}/${resolution}/${from}/${to}/`,
    {}
  );
};
